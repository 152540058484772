import React from 'react';
import PropTypes from 'prop-types';
import VideoBackground from '../../../responder/videoplayer/VideoBackground';
import { setClassName } from '../../../../utils';

function UploadedMedia({ isUploading, files, blockState }) {
  if (isUploading) {
    return files.map((file) => {
      if (file.type.includes('image/')) {
        return (
          <img
            data-testid="UploadMediaSection/Image"
            key={file.name}
            src={file.preview}
            className={`media-background__image ${setClassName(
              'fit-to-size',
              blockState.backgroundLayout === 'fit_to_size',
            )}`}
          />
        );
      } else if (file.type.includes('video/')) {
        return (
          <VideoBackground
            key={file.name}
            sources={[file.preview]}
            backgroundLayout={blockState.backgroundLayout}
          />
        );
      }
    });
  }

  if (blockState.hasAttachedImage) {
    return (
      <img
        data-testid="UploadMediaSection/Image"
        src={blockState.imageUrl}
        className={`media-background__image ${setClassName(
          'fit-to-size',
          blockState.backgroundLayout === 'fit_to_size',
        )}`}
      />
    );
  }

  if (blockState.hasAttachedVideo) {
    return (
      <VideoBackground
        sources={[blockState.videoUrl]}
        backgroundLayout={blockState.backgroundLayout}
      />
    );
  }

  return null;
}

UploadedMedia.propTypes = {
  isUploading: PropTypes.bool.isRequired,
  blockState: PropTypes.shape({
    backgroundLayout: PropTypes.string,
    imageUrl: PropTypes.string,
    videoUrl: PropTypes.string,
    hasAttachedVideo: PropTypes.bool,
    hasAttachedImage: PropTypes.bool,
  }),
};

export default UploadedMedia;
