import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import MediaBackground from './components/MediaBackground';
import { BackgroundContainer, ColorOverlay, MediaContainer } from './style';
import BackgroundOverlay from '../../../shared/BackgroundOverlay';
import PreviewTitle from '../PreviewTitle';

function FormContainer({
  hasMedia,
  backgroundSettings,
  backgroundLayout,
  imageUrl,
  videoUrl,
  onPlayButtonClick,
  withShareIcon,
  titleHtml,
  titleStyle,
  handlePreviewLinkClick,
  staticTitle,
  hidePlayButton,
  contentLayout,
  children,
}) {
  const {
    mediaColorOverlay,
    mediaHiddenOnMobile,
    backgroundMediaImageUrl,
    backgroundMediaVideoSources,
  } = backgroundSettings;
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const mediaOverlayStyles = { backgroundColor: mediaColorOverlay };

  return (
    <>
      {contentLayout === 'autofit' && (
        <PreviewTitle
          isVisible={hidePlayButton || !videoIsPlaying}
          titleHtml={titleHtml}
          titleStyle={titleStyle}
          handlePreviewLinkClick={handlePreviewLinkClick}
          staticTitle={staticTitle}
        />
      )}
      <BackgroundContainer data-testid="Preview/BackgroundContainer">
        {children}
      </BackgroundContainer>
      <MediaContainer>
        <BackgroundOverlay
          imageUrl={backgroundMediaImageUrl}
          videoSources={backgroundMediaVideoSources}
          settings={backgroundSettings}
        />
      </MediaContainer>
      {hasMedia && !mediaHiddenOnMobile && (
        <>
          <MediaBackground
            imageUrl={imageUrl}
            videoUrl={videoUrl}
            setVideoIsPlaying={setVideoIsPlaying}
            onPlayButtonClick={onPlayButtonClick}
            backgroundLayout={backgroundLayout}
            withShareIcon={withShareIcon}
            hidePlayButton={hidePlayButton}
          />
          <ColorOverlay
            style={mediaOverlayStyles}
            data-testid="Preview/MediaColorOverlay"
          />
        </>
      )}
    </>
  );
}

FormContainer.propTypes = {
  hasMedia: PropTypes.bool,
  backgroundSettings: PropTypes.shape({
    mediaColorOverlay: PropTypes.string,
    backgroundColorOverlay: PropTypes.string,
    backgroundMediaImageUrl: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]),
    backgroundMediaVideoSources: PropTypes.array,
    backgroundMediaOpacity: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  backgroundLayout: PropTypes.string,
  imageUrl: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  videoUrl: PropTypes.string,
  onPlayButtonClick: PropTypes.func,
  withShareIcon: PropTypes.bool,
  titleHtml: PropTypes.string,
  staticTitle: PropTypes.bool,
};

export default FormContainer;
