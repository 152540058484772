import React from 'react';
import useBuildMentionsSteps from '../../../../../hooks/useBuildMentionsSteps';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { getSelectDefaultValue, buildConditionErrors } from '../../../../TransitionButton/utils';
import {
  REDIRECT_TRANSITION,
  GO_TO_TRANSITION,
} from '../../../../../../constants';
import { NewItemButton } from '../../../../../Button';
import ConditionsSelect from '../../../ConditionsSelect';
import Condition from '../Condition';
import { DropdownMenu } from '../../../../../DropdownMenu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

function TransitionRule({
  ruleIndex,
  experienceId,
  selectOptions,
  transition,
  setTransitions,
  onKeyDown,
  experienceUrlMentionSteps,
  blockUrlMentionSteps,
  errors,
}) {
  if (transition._destroy) {
    return <div />;
  }

  const mentionSteps = useBuildMentionsSteps({
    translationsKeyPrefix: 'TitleSection',
  });

  const defaultSelectValue = getSelectDefaultValue(transition, selectOptions);
  const isRedirectOptionSelected =
    transition.transition_type === REDIRECT_TRANSITION;
  const isGoToOptionSelected = transition.transition_type === GO_TO_TRANSITION;

  const handleSelectChange = (option) => {
    setTransitions((prev) =>
      prev.map((t, i) => {
        if (i === ruleIndex) {
          return {
            ...prev[i],
            transition_type: option.value,
            redirect_url: '',
            to_block_id: option.data?.to_block_id,
          };
        } else {
          return t;
        }
      }),
    );
  };

  const handleSelectedConditionChange = (value) => {
    setTransitions((prev) =>
      prev.map((t, i) => {
        if (i === ruleIndex) {
          return {
            ...prev[i],
            redirect_url: value,
          };
        } else {
          return t;
        }
      }),
    );
  };

  const handleDelete = () => {
    if (transition.id) {
      setTransitions((prev) =>
        prev.map((t, i) => {
          if (i === ruleIndex) {
            return {
              id: t.id,
              _destroy: true,
            };
          } else {
            return t;
          }
        }),
      );
    } else {
      setTransitions((prev) => prev.filter((_t, i) => i !== ruleIndex));
    }
  };

  const handleAddCondition = () => {
    setTransitions((prev) =>
      prev.map((t, i) => {
        if (i === ruleIndex) {
          return {
            ...prev[i],
            conditions_attributes: [...prev[i].conditions_attributes, { first_value: '', second_value: '', operator: 'equals' }],
          };
        } else {
          return t;
        }
      }),
    );
  };

  return (
    <Box aria-label="transition rule" sx={{ display: 'flex', pb: 2 }}>
      <Stack sx={{ width: 'calc(100% - 34px)' }} gap={1}>
        <Box>
          <ConditionsSelect
            selectProps={{
              options: selectOptions,
              defaultValue: defaultSelectValue,
              onChange: handleSelectChange,
              name: '',
              id: '',
            }}
            conditionProps={{
              name: 'redirect_url_if',
              redirectMentionSteps: experienceUrlMentionSteps,
              goToMentionSteps: blockUrlMentionSteps,
              experienceId,
              onValueChange: handleSelectedConditionChange,
              onKeyDown,
              initialValue: transition.redirect_url || '',
              autoFocus: !transition.redirect_url,
              error: errors[ruleIndex]?.redirect_url,
            }}
            isRedirectOptionSelected={isRedirectOptionSelected}
            isGoToOptionSelected={isGoToOptionSelected}
          />
        </Box>
        {transition.conditions_attributes.map((condition, i) => (
          <Condition
            key={i}
            conditionIndex={i}
            ruleIndex={ruleIndex}
            label={i === 0 ? 'If' : 'And'}
            experienceId={experienceId}
            mentionSteps={mentionSteps}
            conditionAttributes={condition}
            onChange={setTransitions}
            onKeyDown={onKeyDown}
            errors={buildConditionErrors(errors, ruleIndex, i)}
          />
        ))}
        <NewItemButton size="small" onClick={handleAddCondition}>
          + Add condition
        </NewItemButton>
      </Stack>
      <DropdownMenu
        menuItems={[
          {
            title: 'Delete rule',
            value: 'delete',
            icon: <DeleteOutlineIcon />,
          },
        ]}
        triggerButton={<MoreHorizIcon />}
        onSelection={handleDelete}
        disablePortal
      />
    </Box>
  );
}

export default TransitionRule;
