import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Menu, MenuItem } from 'react-aria-menubutton';
import { MenuContent, MenuItemSpan, MenuLink } from './style';

function DropdownMenu({ position, listStyle, menuItems }) {
  return (
    <Menu>
      <MenuContent>
        <ul
          className={clsx({
            'AriaMenuButton-menu': true,
            'AriaMenuButton-menu--left': position,
            'AriaMenuButton-menu--right': position === 'right',
          })}
          style={listStyle}
        >
          {menuItems.map((item, i) => {
            const { title, value, href, target, method, icon } = item;
            return (
              <li className="AriaMenuButton-menuItemWrapper" key={i}>
                <div>
                  <MenuItem
                    className={`AriaMenuButton-menuItem ${title.toLowerCase()}-button`}
                    value={value || title}
                    data-testid={`DropdownMenuItem/${value}`}
                  >
                    {href ? (
                      <MenuLink data-method={method} target={target} href={href}>
                        {icon} {title}
                      </MenuLink>
                    ) : (
                      <MenuItemSpan>
                        {icon} {title}{' '}
                      </MenuItemSpan>
                    )}
                  </MenuItem>
                </div>
              </li>
            );
          })}
        </ul>
      </MenuContent>
    </Menu>
  );
}

DropdownMenu.propTypes = {
  position: PropTypes.string,
  listStyle: PropTypes.shape({}),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      target: PropTypes.string,
      href: PropTypes.string,
    }),
  ).isRequired,
};

DropdownMenu.defaultProps = {
  position: '',
  listStyle: null,
  target: '_self',
  method: 'GET',
};

export default DropdownMenu;
