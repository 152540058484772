import React from 'react';
import ScheduleActionSection from './components/ScheduleActionSection';
import Box from '@mui/material/Box';

const delaySelectOptions = [
  { value: 'minute', label: 'Minutes' },
  { value: 'hour', label: 'Hours' },
  { value: 'day', label: 'Days' },
];

const recurringSelectOptions = [
  { value: 'daily', label: 'Day' },
  { value: 'weekly', label: 'Week' },
  { value: 'monthly', label: 'Month' },
  { value: 'yearly', label: 'Year' },
];

export default function ScheduleActions({
  attributes,
  errors,
  withoutRecurring,
}) {
  const { delay, delayBy, interval, frequency } = attributes;
  return (
    <>
      <Box aria-label="delay action section">
        <ScheduleActionSection
          switchTitle="Delay action"
          label="Delay by"
          inputPrefix="delay"
          selectPrefix="delay_by"
          defaultInputValue={delay}
          defaultSelectValue={delayBy}
          selectOptions={delaySelectOptions}
          errors={errors}
        />
      </Box>
      {!withoutRecurring && (
        <Box aria-label="repeat action section">
          <ScheduleActionSection
            switchTitle="Repeat this action"
            label="Every"
            inputPrefix="interval"
            selectPrefix="frequency"
            defaultInputValue={interval}
            defaultSelectValue={frequency}
            selectOptions={recurringSelectOptions}
            disabledFields
            errors={errors}
          />
        </Box>
      )}
    </>
  );
}
