import React from 'react';
import PropTypes from 'prop-types';
import { ChevronDown } from 'react-feather';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _omit from 'lodash/omit';

import { withTranslation } from '../../../i18n';
import Dropdown from '../../dropdown';
import ConditionalTransitionForm from '../transitions/ConditionalTransitionForm';
import RedirectForm from '../transitions/RedirectForm';
import { getExperiencesList, getBlocksList, updateTransition } from '../../api';
import { menuItems, buildOptions } from './utils';
const isEqual = require('fast-deep-equal/es6/react');

// Constants
import {
  NEXT_TRANSITION,
  SUBMIT_TRANSITION,
  CONDITIONAL_TRANSITION,
  REDIRECT_TRANSITION,
  GO_TO_TRANSITION,
  PILL_TITLES,
  REDIRECT_TRANSITION_ERROR_MESSAGE,
  GO_TO_TRANSITION_ERROR_MESSAGE,
} from '../../../constants';

// Styles
import {
  PillButtonWrapper,
  PillButton,
  ButtonTitle,
  ButtonIcon,
} from './style';

// Disable because it does not work on production and test
// Use global styles from assets/components/react/dropdown.scss till the issue
// will be resolved
// import './index.scss';

const buildBlockUrlMentionSteps = (t) => [
  {
    type: 'experiences',
    title: t('TransitionButton.mentions.expTitle'),
    referencePrefix: 'experience_',
    loadList: getExperiencesList,
    skipFieldRender: true,
  },
  {
    type: 'blocks',
    title: 'Blocks',
    referencePrefix: 'block_url_',
    loadList: getBlocksList,
  },
];

const buildExperienceUrlMentionStep = (t) => [
  {
    type: 'experiences',
    title: t('TransitionButton.mentions.expTitle'),
    referencePrefix: 'experience_url_',
    loadList: getExperiencesList,
  },
];

const buildMentionSteps = (t, transitionType) => {
  switch (transitionType) {
    case GO_TO_TRANSITION:
      return buildBlockUrlMentionSteps(t);
    case REDIRECT_TRANSITION:
      return buildExperienceUrlMentionStep(t);
  }
};

class TransitionButton extends React.Component {
  constructor(props) {
    super(props);

    const transitionObject = _get(
      this.props.transitionObject,
      'data.attributes',
      null,
    );

    const conditions = {
      multiple_choice_options: this.props.multipleChoiceOptions,
      sub_transitions: _get(
        transitionObject.sub_transitions,
        'data',
        null,
      ),
      skip_transition: _get(
        transitionObject.skip_transition,
        'data.attributes',
        null,
      ),
    };

    const initialState = {
      redirectFormVisible: false,
      conditionsFormVisible: false,
      transitionType: _get(
        this.props.transitionObject,
        'data.attributes.transition_type',
        null,
      ),
      title:
        PILL_TITLES[
          _get(
            this.props.transitionObject,
            'data.attributes.transition_type',
            NEXT_TRANSITION,
          )
        ],
      redirect_url: _get(
        this.props.transitionObject,
        'data.attributes.redirect_url',
        '',
      ),
      experienceBlocks: this.props.experienceBlocks,
      conditions,
      blockType: this.props.blockType,
      transitionTypeInPill: _get(
        this.props.transitionObject,
        'data.attributes.transition_type',
        null,
      ),
      errors: {},
      transitionObject,
    };

    this.state = {
      ...initialState,
      initialState,
    };

    // Pill actions
    this.handleDropdownSelection = this.handleDropdownSelection.bind(this);
    this.handleDropdownToggle = this.handleDropdownToggle.bind(this);

    // Forms actions
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSaveRedirect = this.handleSaveRedirect.bind(this);
    this.handleSaveTransitionForm = this.handleSaveTransitionForm.bind(this);
    this.handleRedirectFormChange = this.handleRedirectFormChange.bind(this);
    this.onMultipleChoiceChange = this.onMultipleChoiceChange.bind(this);
    this.onSkipTransitionSelectChange = this.onSkipTransitionSelectChange.bind(
      this,
    );
    this.setStateWithBackup = this.setStateWithBackup.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isEqual(this.props, nextProps)) {
      return true;
    }

    if (!isEqual(this.state, nextState)) {
      return true;
    }

    return false;
  }

  setStateWithBackup(stateChange) {
    this.setState({
      ...this.state,
      ...stateChange,
      initialState: {
        ...this.state.initialState,
        ..._omit(this.state, 'initialState'),
        ...stateChange,
      },
    });
  }

  hideAllForms() {
    this.scrollToBlock();
    this.setStateWithBackup({
      ...this.state.initialState,
      redirectFormVisible: false,
      conditionsFormVisible: false,
    });
  }

  scrollToBlock = () => {
    this.props.blockRef.current.parentNode.scrollIntoView();
  };

  onSkipTransitionSelectChange(option) {
    const conditions = _cloneDeep(this.state.conditions);

    conditions.skip_transition = {
      id: conditions.skip_transition.id,
      transition_type: option.value,
      to_block_id: (option.data && option.data.to_block_id) || null,
      redirect_url: null,
    };

    this.setState({ conditions });
  }

  onMultipleChoiceChange(option, index) {
    const conditions = _cloneDeep(this.state.conditions);

    const value = {
      id: conditions.multiple_choice_options[index].transition.id,
      transition_type: option.value,
      to_block_id: (option.data && option.data.to_block_id) || null,
      redirect_url: null,
    };

    conditions.multiple_choice_options[index].transition = value;

    this.setState({
      conditions,
    });
  }

  handleDropdownToggle() {
    const selectedType = this.state.transitionTypeInPill;

    this.setState({
      redirectFormVisible:
        selectedType === REDIRECT_TRANSITION ||
        selectedType === GO_TO_TRANSITION,
      conditionsFormVisible: selectedType === CONDITIONAL_TRANSITION,
    });
  }

  handleDropdownSelection(value) {
    const selectedType = value; // Value of the menu item from dropdown

    if (
      selectedType === NEXT_TRANSITION ||
      selectedType === SUBMIT_TRANSITION
    ) {
      const params = {
        transition: {
          transition_type: selectedType,
        },
      };

      updateTransition(this.props.blockId, params)
        .then((json) => {
          this.setStateWithBackup({
            transitionType: json.data.attributes.transition_type,
            // Update title on the pill from transitionType
            title: PILL_TITLES[json.data.attributes.transition_type],
            transitionTypeInPill: selectedType,
          });
        })
        .catch((error) => {
          window.exceptionHandler.notify(error);
        });

      this.hideAllForms();
    } else if (selectedType === CONDITIONAL_TRANSITION) {
      this.setState({
        title: PILL_TITLES[selectedType],
        redirectFormVisible: false,
        conditionsFormVisible: true,
        transitionTypeInPill: selectedType,
      });
    } else {
      this.setState({
        title: PILL_TITLES[selectedType],
        redirectFormVisible: true,
        conditionsFormVisible: false,
        transitionTypeInPill: selectedType,
      });
    }
  }

  validateRedirectForm(redirect_url) {
    const errors = {};

    // TODO: Fix this convertion in a different way.
    // For example set default value to '' in state
    // But it should be synchronized with backend
    if (_isEmpty(redirect_url)) {
      if (this.state.transitionTypeInPill === GO_TO_TRANSITION) {
        errors.redirect_url = GO_TO_TRANSITION_ERROR_MESSAGE;
      } else {
        errors.redirect_url = REDIRECT_TRANSITION_ERROR_MESSAGE;
      }

      return errors;
    }

    return errors;
  }

  handleRedirectFormChange(value) {
    const errors = this.validateRedirectForm(value);

    this.setState({
      redirect_url: value,
      errors,
    });
  }

  handleCancel(e) {
    e.preventDefault();

    this.hideAllForms();

    this.setState({
      // Update title on the pill from transitionType
      ...this.state.initialState,
      title: PILL_TITLES[this.state.transitionType],
      transitionTypeInPill: this.state.transitionType,
    });
  }

  handleSaveRedirect(e) {
    e.preventDefault();

    const errors = this.validateRedirectForm(this.state.redirect_url);

    if (errors.redirect_url) {
      this.setState({
        errors,
      });
    } else {
      const params = {
        transition: {
          transition_type: this.state.transitionTypeInPill,
          redirect_url: this.state.redirect_url,
        },
      };

      updateTransition(this.props.blockId, params)
        .then((json) => {
          this.setStateWithBackup({
            transitionType: json.data.attributes.transition_type,
            title: PILL_TITLES[json.data.attributes.transition_type],
          });

          this.hideAllForms();
        })
        .catch((error) => {
          const errors = {};
          errors.redirect_url = JSON.parse(
            error.message,
          ).errors.transition.redirect_url[0];

          this.setState({
            errors,
            title: PILL_TITLES[this.state.transitionType],
          });
        });
    }
  }

  handleSaveTransitionForm(subTransitions, elseTransition) {
    const params = {
      transition: {
        transition_type: CONDITIONAL_TRANSITION,
        sub_transitions_attributes: subTransitions.map((t) => _omit(t, ['nanoid'])),
        skip_transition_attributes: _omit(elseTransition, ['transitionable_id', 'transitionable_type']),
      },
    };

    updateTransition(this.props.blockId, params)
      .then((json) => {
        this.setStateWithBackup({
          transitionType: json.data.attributes.transition_type,
          conditions: {
            ...this.state.conditions,
            sub_transitions: json.data.attributes.sub_transitions.data,
            skip_transition: json.data.attributes.skip_transition.data.attributes,
          },
          title: PILL_TITLES[json.data.attributes.transition_type],
          errors: {},
        });

        this.hideAllForms();
      })
      .catch((error) => {
        const errors = {};
        errors.sub_transitions = JSON.parse(
          error.message,
        ).errors.sub_transitions;
        this.setState({
          ...this.state,
          errors,
        });
      });
  }

  renderDisabledPill() {
    return (
      <PillButton disabled>
        <ButtonTitle>Next</ButtonTitle>
        <ButtonIcon>
          <ChevronDown size={16} />
        </ButtonIcon>
      </PillButton>
    );
  }

  renderPill() {
    const actionButton = (
      <PillButton>
        <ButtonTitle>{this.state.title}</ButtonTitle>
        <ButtonIcon>
          <ChevronDown size={16} />
        </ButtonIcon>
      </PillButton>
    );

    return (
      <PillButtonWrapper>
        <Dropdown
          title={this.state.title}
          actionButton={actionButton}
          menuItems={menuItems}
          onSelection={this.handleDropdownSelection}
          onMenuToggle={this.handleDropdownToggle}
        />

        <RedirectForm
          transitionType={this.state.transitionTypeInPill}
          experienceId={this.props.experienceId}
          mentionSteps={buildMentionSteps(
            this.props.t,
            this.state.transitionTypeInPill,
          )}
          visible={this.state.redirectFormVisible}
          url={this.state.redirect_url || ''}
          onChange={this.handleRedirectFormChange}
          onCancel={this.handleCancel}
          onSave={this.handleSaveRedirect}
          errors={this.state.errors}
          isGoToTransition={
            this.state.transitionTypeInPill === GO_TO_TRANSITION
          }
        />

        <ConditionalTransitionForm
          visible={this.state.conditionsFormVisible}
          experienceId={this.props.experienceId}
          mcOptions={this.state.conditions.multiple_choice_options}
          selectOptions={buildOptions(
            this.props.experienceBlocks,
            this.props.blockId,
          )}
          subTransitions={this.state.conditions.sub_transitions}
          initialElseTransition={this.state.conditions.skip_transition}
          onCancel={this.handleCancel}
          onSave={this.handleSaveTransitionForm}
          experienceUrlMentionSteps={buildExperienceUrlMentionStep(
            this.props.t,
          )}
          blockUrlMentionSteps={buildBlockUrlMentionSteps(this.props.t)}
          errors={this.state.errors}
        />
      </PillButtonWrapper>
    );
  }

  render() {
    if (this.props.transitionObject) {
      return this.renderPill();
    } else {
      return this.renderDisabledPill();
    }
  }
}

TransitionButton.propTypes = {
  title: PropTypes.string.isRequired,
  blockId: PropTypes.number.isRequired,
  experienceId: PropTypes.number,
  blockType: PropTypes.string.isRequired,
  transitionObject: PropTypes.object.isRequired,
  experienceBlocks: PropTypes.array.isRequired,
  multipleChoiceOptions: PropTypes.array.isRequired,
  blockRef: PropTypes.object.isRequired,
};

TransitionButton.defaultProps = {
  title: 'Next',
};

export default withTranslation()(TransitionButton);
