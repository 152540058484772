import React from 'react';
import PropTypes from 'prop-types';

import ChatbotSettings from './components/ChatbotSettings';
import { ExpansionPanel } from './style';

function ChatbotSection({
  uuid,
  chatbotAutoApprove,
  blacklistNumbers,
}) {

  return (
    <ExpansionPanel
      title="Textbot"
      data-testid="ExperienceSettings/chatbotDropdown"
    >
      <ChatbotSettings
        experienceUuid={uuid}
        blacklistNumbers={blacklistNumbers}
        chatbotAutoApprove={chatbotAutoApprove}
      />
    </ExpansionPanel>
  );
}

ChatbotSection.propTypes = {
  uuid: PropTypes.string.isRequired,
  chatbotAutoApprove: PropTypes.bool.isRequired,
  blacklistNumbers: PropTypes.arrayOf(PropTypes.string),
};

export default ChatbotSection;
