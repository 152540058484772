import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SwitchWithTitle } from '../../../../../../../switch';
import SimpleSelect from '../../../../../../../select';
import { Input } from '../../../../../../../inputs/Input';
import { Label } from '../../../../../../../globals';
import Box from '@mui/material/Box';

function ScheduleActionSection({
  label,
  switchTitle,
  inputPrefix,
  selectPrefix,
  defaultInputValue,
  defaultSelectValue,
  selectOptions,
  disabledFields,
}) {
  const [inputValue, setInputValue] = useState(defaultInputValue || 1);
  const [selectValue, setSelectValue] = useState(
    defaultSelectValue
      ? selectOptions.find((o) => o.value === defaultSelectValue)?.value
      : selectOptions[0].value,
  );
  const [isChecked, setIsChecked] = useState(
    !!defaultSelectValue && !!defaultInputValue,
  );

  const handleSwitchCheck = () => {
    setIsChecked(!isChecked);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectValue(selectedOption.value);
  };

  return (
    <Box sx={{ mb: '2rem' }}>
      <SwitchWithTitle
        checked={isChecked}
        onChange={handleSwitchCheck}
        title={switchTitle}
      >
        <Label>{label}</Label>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '0.65fr 1fr',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <Input
            type="number"
            value={inputValue}
            onChange={handleInputChange}
            noMargin
            disabled={disabledFields}
          />
          <input
            type="hidden"
            name={`event_action[${inputPrefix}]`}
            defaultValue={inputValue}
          />
          <SimpleSelect
            classNamePrefix="react-select__action_schedule"
            options={selectOptions}
            onChange={handleSelectChange}
            defaultValue={selectOptions.find((o) => o.value == selectValue)}
            isSearchable={false}
            noMargin
          />
          <input
            type="hidden"
            name={`event_action[${selectPrefix}]`}
            defaultValue={selectValue}
          />
        </Box>
      </SwitchWithTitle>
      {!isChecked && (
        <>
          <input
            type="hidden"
            name={`event_action[${inputPrefix}]`}
            defaultValue={null}
          />
          <input
            type="hidden"
            name={`event_action[${selectPrefix}]`}
            defaultValue={null}
          />
        </>
      )}
    </Box>
  );
}

ScheduleActionSection.propTypes = {
  label: PropTypes.string,
  switchTitle: PropTypes.string,
  inputPrefix: PropTypes.string.isRequired,
  selectPrefix: PropTypes.string.isRequired,
  defaultInputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultSelectValue: PropTypes.string,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  disabledFields: PropTypes.bool,
};

export default ScheduleActionSection;
