import React from 'react';
import PropTypes from 'prop-types';
import EmailActionForm from '../EmailActionForm';
import TextbotActionForm from '../TextbotActionForm';
import CancelRecurringActionForm from '../CancelRecurringActionForm';
import HubspotActionForm from '../HubspotActionForm';

function ActionFormWrapper({ type, ...props }) {
  switch (type) {
    case 'email':
      return <EmailActionForm {...props} />;
    case 'textbot':
      return <TextbotActionForm {...props} />;
    case 'cancel_recurring':
      return <CancelRecurringActionForm {...props} />;
    case 'integration':
      return <HubspotActionForm {...props} />;
  }
};

ActionFormWrapper.propTypes = {
  experienceId: PropTypes.number,
  errors: PropTypes.object,
  serializedErrors: PropTypes.object.isRequired,
  attributes: PropTypes.shape({
    uuid: PropTypes.string,
    actionType: PropTypes.string,
    emailBody: PropTypes.string,
    emailSubject: PropTypes.string,
    emailTos: PropTypes.string,
    emailCcs: PropTypes.string,
    emailBccs: PropTypes.string,
    senderEmail: PropTypes.string,
    senderName: PropTypes.string,
    textbotNumbers: PropTypes.string,
    targetExperience: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  }),
};

export default ActionFormWrapper;
