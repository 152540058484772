import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  overflow-y: ${props => props.contentLayout === 'hug_content' ? 'visible' : 'auto'};
  z-index: 2;
`;

export const InnerContainer = styled.div`
  padding-bottom: 12px;
`;

export const DisclaimerWrapper = styled.div`
  padding: 0.5rem;
  margin-top: 1rem;

  & span {
    font-size: 15px;
    color: #fff;
  }
`;
