import styled from 'styled-components';

export const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-secondary);
  border: 1px solid var(--border-color-primary);
  padding: 28px;
  border-radius: 8px;
  margin-bottom: 32px;
`;

export const Message = styled.p`
  color: var(--font-color-secondary);
  max-width: 240px;
  text-align: center;
  margin: 0;
`;
