import React from 'react';
import PropTypes from 'prop-types';
import { Edit3 } from 'react-feather';
import MentionTitle from '../../../MentionTitle';
import { Title, EditIconWrapper } from './style';

function TitleWithReferencesTags({ value, onClick }) {
  return (
    <>
      <Title data-testid="EditableExperienceTitle/Title" onClick={onClick}>
        <MentionTitle title={value} />
      </Title>
      <EditIconWrapper
        data-testid="EditableExperienceTitle/EditIcon"
        onClick={onClick}
      >
        <Edit3 size={18} />
      </EditIconWrapper>
    </>
  );
}

Title.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default TitleWithReferencesTags;
