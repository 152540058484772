import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { deleteMedia } from '../../../api';

function InitialStateButtons({
  hidden,
  askUuid,
  hasAttachedMedia,
  backgroundVideoPath,
  openUploadDialog,
  videoButtonText,
  uploadButtonText,
}) {
  if (hidden) {
    return null;
  }

  const [isDisabled, setIsDisabled] = useState(false);

  const removeMedia = () => {
    setIsDisabled(true);

    deleteMedia(askUuid).then((response) => {
      if (response.ok) {
        window.location.reload();
      } else {
        setIsDisabled(false);
      }
    });
  };

  return (
    <>
      {hasAttachedMedia && (
        <button
          data-testid="InitialStateButtons/DeleteButton"
          type="button"
          className="media-background__button media-background__button--delete"
          onClick={removeMedia}
          disabled={isDisabled}
        />
      )}
      <a
        className="button media-background__button media-background__button--record mr-12"
        href={backgroundVideoPath}
      >
        {videoButtonText}
      </a>
      <button
        onClick={openUploadDialog}
        className="button media-background__button media-background__button--upload"
      >
        {uploadButtonText}
      </button>
    </>
  );
}

InitialStateButtons.propTypes = {
  hidden: PropTypes.bool,
  askUuid: PropTypes.string,
  hasAttachedMedia: PropTypes.bool,
  backgroundVideoPath: PropTypes.string,
  openUploadDialog: PropTypes.func,
  videoButtonText: PropTypes.string,
  uploadButtonText: PropTypes.string,
};

export default InitialStateButtons;
