import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isHotkey from 'is-hotkey';
import difference from 'lodash/xor';
import { useClickOutside } from 'use-events';
import { Container } from './style';
import TitleWithReferencesTags from './components/TitleWithReferencesTags';
import { updateExperience } from '../api';
import { EditableMentionsInput } from '../SlateElements';
import useBuildMentionsSteps from '../hooks/useBuildMentionsSteps';
import { ReferencesProvider } from '../../contexts/ReferencesContext';

function EditableExperienceTitle(props) {
  const { experienceTitle, experienceId } = props;

  const [isEditActive, setIsEditActive] = useState(false);
  const [prevTitle, setPrevTitle] = useState(null);
  const [title, setTitle] = useState(experienceTitle);

  const mentionSteps = useBuildMentionsSteps({
    translationsKeyPrefix: 'TitleSection',
  });
  const openEdit = () => {
    setIsEditActive(true);
  };

  const closeEdit = () => {
    setIsEditActive(false);

    updateExperience(experienceId, { experience: { title } }).then(
      (response) => {
        setTitle(response.experience.title);
      },
    );
  };

  const handleKeyDown = (event) => {
    if (isHotkey('enter', event)) {
      event.preventDefault();
      const diff = difference(prevTitle.split(''), title.split('')).join('');

      if (!diff.includes('[')) {
        closeEdit();
      }
    }
  };

  const ref = React.useRef(null);
  useClickOutside([ref], () => {
    if (isEditActive) {
      closeEdit();
    }
  });

  return (
    <ReferencesProvider>
      <div data-testid="EditableExperienceTitle">
        <Container onKeyDown={handleKeyDown} ref={ref}>
          {isEditActive ? (
            <EditableMentionsInput
              experienceId={experienceId}
              name="EditableExperienceTitle/mentionsInput"
              initialValue={title}
              onValueChange={(t) => {
                setPrevTitle(title);
                setTitle(t);
              }}
              steps={mentionSteps}
              data-testid="EditableExperienceTitle/Input"
              inputStyles={{
                background: 'var(--background-secondary)',
                color: 'var(--font-color-primary)',
                border: '1px solid var(--border-color-primary)',
              }}
              containerPosition="absolute"
              withHiddenInput
              autoFocus
            />
          ) : (
            <TitleWithReferencesTags value={title} onClick={openEdit} />
          )}
        </Container>
      </div>
    </ReferencesProvider>
  );
}

EditableExperienceTitle.propTypes = {
  defaultTitleElements: PropTypes.string,
};

export default EditableExperienceTitle;
