import React from 'react';

import BasicLoader from '../../loader';

export function Loader() {
  const loaderWrapper = {
    style: {
      right: 'unset',
      top: 'unset',
      transform: 'translateX(-50%)',
      marginTop: '90px',
      left: '50%',
    },
  };
  const loader = {
    size: 15,
  };

  return <BasicLoader loaderWrapper={loaderWrapper} loader={loader} />;
}
