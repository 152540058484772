import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { useModal } from '../../../../../../../hooks';
import EmailBuilderModal from '../../../../../../../modals/EmailBuilderModal';
import { ErrorMessageWrapper } from '../../../../style';
import { ErrorMessage } from '../../../../../../../globals';
import EmailBuilderNew from '../EmailBuilderNew';
import EmailBuilderEdit from '../EmailBuilderEdit';
import ChooseEmailTemplateModal from '../../../../../../../modals/ChooseEmailTemplateModal';

function EmailBuilder({
  email,
  handleInitCustomEmail,
  handleSaveTemplate,
  handleChooseTemplate,
  handleDeleteEmail,
}) {
  const {
    isOpen: isOpenBuilderModal,
    hideModal: hideBuilderModal,
    showModal: showBuilderModal,
  } = useModal();
  const {
    isOpen: isOpenTemplateModal,
    hideModal: hideTemplateModal,
    showModal: showTemplateModal,
  } = useModal();

  useEffect(() => {
    handleInitCustomEmail();
  }, []);

  const handleTemplateModalContinue = (selectedTemplate) => {
    handleChooseTemplate(selectedTemplate.id !== 'new' && selectedTemplate);
    showBuilderModal();
  };

  return (
    <div style={{ position: 'relative' }}>
      <EmailBuilderModal
        isOpen={isOpenBuilderModal}
        hideModal={hideBuilderModal}
        customEmail={email.unlayerJsonData}
        handleSaveTemplate={handleSaveTemplate}
        handleInitCustomEmail={handleInitCustomEmail}
      />
      <ChooseEmailTemplateModal
        variant="workspace"
        isOpen={isOpenTemplateModal}
        hideModal={hideTemplateModal}
        onContinue={handleTemplateModalContinue}
      />
      {(email.id || !_isEmpty(email.unlayerJsonData)) && !email.error ? (
        <EmailBuilderEdit
          customEmail={email}
          showEmailBuilder={showBuilderModal}
          deleteEmail={handleDeleteEmail}
        />
      ) : (
        <EmailBuilderNew showEmailBuilder={showTemplateModal} />
      )}
      {email.error && (
        <ErrorMessageWrapper>
          <ErrorMessage>{email.error}</ErrorMessage>
        </ErrorMessageWrapper>
      )}
    </div>
  );
}

EmailBuilder.propTypes = {
  emailBuilderTitle: PropTypes.string,
  emailBuilderData: PropTypes.shape({}),
  lastEdited: PropTypes.string,
  emailPreviewUrl: PropTypes.string,
  error: PropTypes.string,
};

export default EmailBuilder;
