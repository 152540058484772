import styled from 'styled-components';

export const DropdownItemWrapper = styled.div`
  width: 210px;
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--font-color-secondary--dark);
  font-size: 0.875rem;
  line-height: 1.5rem;
`;

export const DropdownItemIcon = styled.div`
  display: flex;
  height: 100%;

  img {
    margin: 0 10px;
    width: 24px;
  }

  svg {
    margin: 12px 10px 0;
    width: 20px;
    stroke: var(--font-color-secondary);
  }
`;
