import React from 'react';
// Icons
// TODO: HA-13855 choose another icon instead of ConditionsIcon from react-feather
import ConditionsIcon from '../../icons/ConditionsIcon';
import {
  TrendingUp,
  ChevronRight,
  CornerDownRight,
  Check,
} from 'react-feather';

import {
  NEXT_TRANSITION,
  SUBMIT_TRANSITION,
  CONDITIONAL_TRANSITION,
  REDIRECT_TRANSITION,
  GO_TO_TRANSITION,
  BLOCK_TRANSITION,
} from '../../../constants';
import _isEmpty from 'lodash/isEmpty';

export const menuItems = [
  {
    icon: <ChevronRight />,
    title: 'Next',
    value: NEXT_TRANSITION,
  },
  {
    icon: <ConditionsIcon />,
    title: 'Conditions',
    value: CONDITIONAL_TRANSITION,
  },
  {
    icon: <CornerDownRight />,
    title: 'Go To',
    value: GO_TO_TRANSITION,
  },
  {
    icon: <TrendingUp />,
    title: 'Redirect',
    value: REDIRECT_TRANSITION,
  },
  {
    icon: <Check />,
    title: 'Submit',
    value: SUBMIT_TRANSITION,
  },
];

export const buildOptions = (blocks, currentBlockId) => {
  const options = [{ value: NEXT_TRANSITION, label: 'Next' }];

  blocks.map((b, index) => {
    if (b.id === currentBlockId) {
      return;
    }
    options.push({
      value: BLOCK_TRANSITION,
      label: `${index + 1} - ${b.title}`,
      data: { to_block_id: b.id },
    });
  });

  options.push(
    { value: GO_TO_TRANSITION, label: 'Go To' },
    { value: REDIRECT_TRANSITION, label: 'Redirect' },
    { value: SUBMIT_TRANSITION, label: 'Submit' },
  );

  return options;
};

export const getSelectDefaultValue = (transition, options) => {
  let defaultValue = options.find(
    (x) => x.value === transition.transition_type,
  );
  if (defaultValue) {
    if (defaultValue.value === BLOCK_TRANSITION) {
      const allBlocksOptions = options.filter(
        (x) => x.value === BLOCK_TRANSITION,
      );

      defaultValue = allBlocksOptions.find(
        (x) => x.data.to_block_id === transition.to_block_id,
      );
    }

    if (defaultValue) {
      return defaultValue;
    } else {
      return options[0];
    }
  } else {
    return options[0];
  }
};

export const buildConditionErrors = (errors, ruleIndex, conditionIndex) => {
  if (_isEmpty(errors)) {
    return {};
  }

  if (_isEmpty(errors[ruleIndex]) || _isEmpty(errors[ruleIndex]?.conditions)) {
    return {};
  }

  return errors[ruleIndex].conditions[conditionIndex];
};
