import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import api from '../api-client';
import { useDropzone } from 'react-dropzone';
import UploadingState from './components/UploadingState';
import InitialStateButtons from './components/InitialStateButtons';
import { uploadMediaValidator } from './uploadMediaValidator';
import makeToast from '../toast';
import { updateMedia } from '../api';
import UploadedMedia from './components/UploadedMedia';

const requestParamsMapper = {
  image: 'background_image_signed_id',
  video: 'background_video_signed_id',
};

function UploadMediaSection({ directUploadUrl, backgroundVideoPath, askUuid }) {
  const [files, setFiles] = React.useState([]);
  const [blockState, setBlockState] = React.useState({});
  const [isUploading, setIsUploading] = React.useState(false);

  const onDropAccepted = (acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
    setIsUploading(true);
  };

  const onDropRejected = (rejectedFiles) => {
    rejectedFiles.map(({ errors }) => {
      const errorMessage = errors[1] ? errors[1].message : errors[0].message;

      makeToast(errorMessage, 'error');
    });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept:
      'video/mp4,video/webm,video/quicktime,image/jpeg,image/pjpeg,image/png,image/gif',
    noClick: true,
    multiple: false,
    validator: uploadMediaValidator,
    onDropAccepted,
    onDropRejected,
  });

  useEffect(() => {
    api.show('asks', { id: askUuid }).then((response) => {
      setBlockState(response.data.data.attributes);
    });
  }, []);

  const uploadCreateCallback = (error, { content_type, signed_id }) => {
    if (error) {
      setIsUploading(false);
    } else {
      const contentType = content_type.slice(0, content_type.indexOf('/'));
      const params = {
        ask: { [requestParamsMapper[contentType]]: signed_id },
      };

      updateMedia(askUuid, params)
        .then((response) => {
          if (response.ok) {
            window.location.reload();
          }
        })
        .catch(() => {
          makeToast('Something went wrong. Try again later.', 'error');
        });
    }
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  return (
    <>
      <p className="media-background__subtitle">Upload media</p>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="media-background__media-wrapper">
          <UploadedMedia
            isUploading={isUploading}
            files={files}
            blockState={blockState}
          />
        </div>
      </div>
      <div className="media-background__controls">
        {!isUploading && (
          <h3 className="media-background__controls-title">Change Media</h3>
        )}
        <div className="media-background__buttons-container">
          <InitialStateButtons
            hidden={isUploading}
            askUuid={askUuid}
            hasAttachedMedia={blockState.hasMedia}
            backgroundVideoPath={backgroundVideoPath}
            openUploadDialog={open}
            videoButtonText="Video"
            uploadButtonText="Upload"
          />
          <UploadingState
            hidden={!isUploading}
            uploadCreateCallback={uploadCreateCallback}
            file={files[0]}
            uploadUrl={directUploadUrl}
            refreshState={() => setIsUploading(false)}
          />
        </div>
        {!isUploading && (
          <p className="media-background__hint">
            Works with jpg, png, gif and video
          </p>
        )}
      </div>
    </>
  );
}

UploadMediaSection.propTypes = {
  directUploadUrl: PropTypes.string.isRequired,
  backgroundVideoPath: PropTypes.string.isRequired,
  askUuid: PropTypes.string.isRequired,
};

export default UploadMediaSection;
