import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-aria-menubutton';

function ToggleButton({ actionButton }) {
  if (actionButton) {
    return <Button className="AriaMenuButton-button">{actionButton}</Button>;
  }
  return (
    <Button className="AriaMenuButton-button">
      <button>Button</button>
    </Button>
  );
}

ToggleButton.propTypes = {
  actionButton: PropTypes.node,
};

ToggleButton.defaultProps = {
  actionButton: null,
};

export default ToggleButton;
