import React from 'react';
import {
  EditableMentionsInput,
  EditableReadOnlyMentionsInput,
} from '../../../../../SlateElements';

const inputStyle = {
  borderTopRightRadius: '0px',
  borderTopLeftRadius: '0px',
  marginTop: '1px',
  paddingTop: '0.4rem',
};

function SelectedCondition({
  isRedirect,
  isGoTo,
  name,
  redirectMentionSteps,
  goToMentionSteps,
  experienceId,
  onValueChange,
  onKeyDown,
  initialValue,
  autoFocus,
  error,
}) {
  if (isRedirect) {
    return (
      <EditableMentionsInput
        dataTestId="MultipleChoicesForm/Input"
        placeholder="Enter redirect URL"
        name={name}
        experienceId={experienceId}
        steps={redirectMentionSteps}
        onValueChange={onValueChange}
        extendKeyBoardNavigation={onKeyDown}
        initialValue={initialValue}
        inputStyles={inputStyle}
        autoFocus={autoFocus}
        error={error}
        withHiddenInput
        noTrailingSpace
      />
    );
  }

  if (isGoTo) {
    return (
      <EditableReadOnlyMentionsInput
        dataTestId="MultipleChoicesForm/Input"
        placeholder="Select a block"
        name={name}
        steps={goToMentionSteps}
        experienceId={experienceId}
        onValueChange={onValueChange}
        initialValue={initialValue}
        inputProps={{
          style: { paddingBottom: '8px', paddingTop: '0' },
        }}
        containerStyle={inputStyle}
        error={error}
        noTrailingSpace
      />
    );
  }

  return null;
}

export default SelectedCondition;
