import React from 'react';
import BlockForm from '../BlockForm';
import { Info } from 'react-feather';
import Preview from '../preview';
import Tooltip from '../tooltip';
import NavigationHeader from '../BlockForm/components/NavigationHeader';
import {
  Container,
  FormWrapper,
  PreviewContainer,
  PreviewWrapper,
  TooltipIconWrapper,
  TooltipWrapper,
} from './style';
// Contexts
import { Context as BlockContext } from '../../contexts/BlockContext';
import { Context as GoalAndMetricContext } from '../../contexts/GoalAndMetricContext';
import { ReferencesProvider } from '../../contexts/ReferencesContext';
import { SnackbarProvider } from 'notistack';

function AskEditPage({
  blockFormProps,
  navigationHeaderProps,
  appSettings,
  workspaceSettings,
}) {
  return (
    <SnackbarProvider
      sx={{
        '@media(max-width: 1103px)': {
          '& .SnackbarContent-root': {
            marginBottom: '60px',
          },
        },
      }}
      dense
      autoHideDuration={3000}
    >
      <Container>
        <BlockContext
          blockFormProps={blockFormProps}
          workspaceSettings={workspaceSettings}
        >
          <GoalAndMetricContext>
            <ReferencesProvider>
              <FormWrapper id="askEditSettings">
                <NavigationHeader {...navigationHeaderProps} />
                <BlockForm {...blockFormProps} />
              </FormWrapper>
              <PreviewContainer>
                <TooltipWrapper>
                  Preview
                  <Tooltip
                    data-testid="AskEditPage/Tooltip"
                    disableFocusListener
                    disableTouchListener
                    title="Preview shows how this block will look on mobile screens. Merge fields will be either blank or filled with response data. Your changes will not be saved until you press Save Changes."
                    placement="bottom"
                    width={280}
                  >
                    <TooltipIconWrapper>
                      <Info size={16} />
                    </TooltipIconWrapper>
                  </Tooltip>
                </TooltipWrapper>
                <PreviewWrapper>
                  <Preview
                    workspace_id={blockFormProps.workspaceId}
                    ask_uuid={blockFormProps.ask_uuid}
                    experience_uuid={blockFormProps.experience_uuid}
                    appSettings={appSettings}
                  />
                </PreviewWrapper>
              </PreviewContainer>
            </ReferencesProvider>
          </GoalAndMetricContext>
        </BlockContext>
      </Container>
    </SnackbarProvider>
  );
}

export default AskEditPage;
