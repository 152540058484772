import React from 'react';
import PropTypes from 'prop-types';
import { ActionCard } from '../ActionCard';

const ActionsList = ({ list }) => {
  return (
    <>
      {list.map(
        ({
          attributes,
          links: { editUrl, deleteUrl },
        }) => {
          return (
            <ActionCard
              key={attributes.uuid}
              attributes={attributes}
              editUrl={editUrl}
              deleteUrl={deleteUrl}
            />
          );
        },
      )}
    </>
  );
};

ActionsList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.object,
      links: PropTypes.object,
    }),
  ),
};

export default ActionsList;
