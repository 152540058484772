import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../modal';
import { useTranslation } from '../../../i18n';
import { deleteExperienceByPath } from '../../api';

function ArchiveFormModal({ archiveLink, isOpen, hideModal, redirectPath }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'ArchiveFormModal',
  });
  const [disabled, setDisabled] = React.useState(false);

  const handleDelete = () => {
    setDisabled(true);
    deleteExperienceByPath(archiveLink)
      .then(() => {
        hideModal();
        if (redirectPath) {
          window.location.href = redirectPath;
        } else {
          window.location.reload();
        }
      })
      .catch((error) => {
        window.exceptionHandler.notify(error);
      });
  };
  const buttons = [
    { title: 'Cancel', onClick: hideModal },
    { title: 'Archive', onClick: handleDelete, primary: true, disabled },
  ];

  return (
    <Modal
      isOpen={isOpen}
      title={t('title')}
      content={t('content')}
      buttons={buttons}
      hideModal={hideModal}
    />
  );
}

ArchiveFormModal.propTypes = {
  archiveLink: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  redirectPath: PropTypes.string,
};

export default ArchiveFormModal;
