import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  getAllExperiencesList,
  getExperiencesList,
  getBlocksList,
  getDataFieldsByType,
} from '../../../../../api';
import SimpleSelect from '../../../../../select';
import { EditableMentionsTextarea } from '../../../../../SlateElements';
import { Label, Fieldset, Hint, ErrorMessage } from '../../../../../globals';
import { textareaStyle, ErrorMessageWrapper } from '../../style';
import TimeSection from '../TimeSection';
import { useTranslation } from '../../../../../../i18n';
import MentionTitle from '../../../../../MentionTitle';
import ConditionalActionSection from '../ConditionalActionSection';
import { buildInitialConditions } from '../../utils';
import ScheduleActions from '../ScheduleActions';
import { getErrorMessage } from '../../../../../../utils';

const buildMentionSteps = (t) => [
  {
    type: 'experiences',
    title: t('mentions.expTitle'),
    referencePrefix: 'experience_',
    loadList: getExperiencesList,
  },
  {
    type: 'blocks',
    title: 'Blocks',
    referencePrefix: 'block_',
    loadList: getBlocksList,
  },
  {
    type: 'merge_fields',
    title: 'Block merge fields',
    referencePrefix: '',
    loadList: (blockId) => getDataFieldsByType(blockId, 'phone'),
  },
];

function TextbotActionForm({ experienceId, errors, serializedErrors, attributes }) {
  const {
    textbotNumbers,
    targetExperience,
    specificTime,
    conditions,
  } = attributes;
  const { t } = useTranslation('translation', {
    keyPrefix: 'ActionCard.TextbotActionForm',
  });

  const [experiencesList, setExperiencesList] = React.useState([]);
  const [selectedExperience, setSelectedExperience] = React.useState(null);

  const mentionSteps = useMemo(() => buildMentionSteps(t), [t]);

  React.useEffect(() => {
    getAllExperiencesList().then((data) => {
      const prepared = data.map((exp) => ({
        label: <MentionTitle title={exp.title} />,
        value: exp.id,
      }));
      const selected = prepared.find(
        (exp) => exp.value === targetExperience?.id,
      );

      setExperiencesList(prepared);
      setSelectedExperience(selected || prepared[0]);
    });
  }, []);

  return (
    <>
      <Fieldset>
        <Label>{t('targerExperienceLabel')}</Label>
        <SimpleSelect
          options={experiencesList}
          value={selectedExperience}
          isSearchable={false}
          error={getErrorMessage(errors, 'target_experience')}
          name="event_action[target_experience_id]"
          classNamePrefix="react-select__actions__target_experience"
          placeholder={t('targerExperiencePlaceholder')}
          onChange={(exp) => setSelectedExperience(exp)}
        />
      </Fieldset>
      <Fieldset style={{ position: 'relative' }}>
        <Label>Send to</Label>
        <Hint>Enter phone number(s) separated by a new line or comma.</Hint>
        <div style={{ position: 'relative' }}>
          <EditableMentionsTextarea
            name="event_action[textbot_numbers]"
            placeholder="Paste a list of phone numbers"
            initialValue={textbotNumbers || ''}
            experienceId={experienceId}
            steps={mentionSteps}
            inputStyle={textareaStyle}
            error={errors.textbot_numbers && errors.textbot_numbers[0]}
          />
          {errors.textbot_numbers && (
            <ErrorMessageWrapper>
              <ErrorMessage>{errors.textbot_numbers[0]}</ErrorMessage>
            </ErrorMessageWrapper>
          )}
        </div>
      </Fieldset>
      <ConditionalActionSection
        initialConditions={buildInitialConditions(conditions)}
        experienceId={experienceId}
        errors={serializedErrors}
      />
      <ScheduleActions attributes={attributes} errors={errors} />
      <TimeSection specificTime={specificTime} />
    </>
  );
}

TextbotActionForm.propTypes = {
  experienceId: PropTypes.number,
  errors: PropTypes.object,
  serializedErrors: PropTypes.object.isRequired,
  attributes: PropTypes.shape({
    interval: PropTypes.number,
    frequency: PropTypes.string,
    specificTime: PropTypes.string,
    textbotNumbers: PropTypes.string,
    targetExperience: PropTypes.shape({
      title: PropTypes.string,
      id: PropTypes.number,
    }),
  }),
};

export default TextbotActionForm;
