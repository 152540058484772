import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, PrimaryButton } from '../../../Button';
import { EditableMentionsInput } from '../../../SlateElements';
import { Form, ButtonsWrapper, CancelButtonWrapper } from './style';
import { GO_TO_TRANSITION, REDIRECT_TRANSITION } from '../../../../constants';

const LABELS = {
  [GO_TO_TRANSITION]: {
    label: 'GO TO',
    placeholder: 'Select a block',
  },
  [REDIRECT_TRANSITION]: {
    label: 'Redirect Url',
    placeholder: 'Enter redirect URL',
  },
};

function RedirectForm({
  transitionType,
  experienceId,
  mentionSteps,
  visible,
  onChange,
  url,
  errors,
  onCancel,
  onSave,
  isGoToTransition,
}) {
  if (!visible) {
    return null;
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSave(event);
    }
  };

  const labels = useMemo(() => LABELS[transitionType], [transitionType]);

  return (
    <Form>
      <label htmlFor="redirect_url" className="form__label">
        {labels.label}
      </label>
      <EditableMentionsInput
        name="redirect_url"
        placeholder={labels.placeholder}
        initialValue={url}
        experienceId={experienceId}
        onValueChange={onChange}
        onKeyDown={handleKeyDown}
        steps={mentionSteps}
        error={errors.redirect_url}
        readOnly={isGoToTransition}
        autoFocus={!isGoToTransition && url === ''}
        inputStyles={{ maxWidth: '504px' }}
        withHiddenInput
        noTrailingSpace
      />
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button onClick={onCancel}>Cancel</Button>
        </CancelButtonWrapper>
        <PrimaryButton onClick={onSave} name="redirect_url_save">
          Save Changes
        </PrimaryButton>
      </ButtonsWrapper>
    </Form>
  );
}

RedirectForm.defaultTypes = {
  visible: false,
  url: '',
};

RedirectForm.propTypes = {
  transitionType: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  experienceId: PropTypes.number,
  mentionSteps: PropTypes.array,
  url: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default RedirectForm;
