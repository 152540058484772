import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SwitchWithTitle } from '../../../../switch';
import { MessageContainer, Message } from './style';
import useWorkspace from '../../../../hooks/useWorkspace';

function CustomBrandingSection({
  customBranding,
  askerHasBranding,
}) {
  const workspaceId = useWorkspace();
  const [isEnabled, setIsEnabled] = useState(customBranding);

  return (
    <>
      <SwitchWithTitle
        title="Add custom branding"
        checked={isEnabled}
        name="experience_settings_form_object[custom_branding]"
        data-testid="ExperienceSettings/customBranding"
        onChange={() => setIsEnabled(!isEnabled)}
      />
      {isEnabled && !askerHasBranding && (
        <MessageContainer data-testid="ExperienceSettings/CustomBrandingSection">
          <Message data-testid="CustomBrandingSection/HelperText">
            Add your own custom branding to display in{' '}
            <a
              data-testid="CustomBrandingSection/AccountSettingsLink"
              href={`/${workspaceId}/settings/branding`}
            >
              account settings
            </a>
            .
          </Message>
        </MessageContainer>
      )}
    </>
  );
}

CustomBrandingSection.propTypes = {
  customBranding: PropTypes.bool.isRequired,
  askerHasBranding: PropTypes.bool.isRequired,
};

export default CustomBrandingSection;
