import styled from 'styled-components';

import ExpansionPanelOrigin from '../../../../expansionPanel';

export const ExpansionPanel = styled(ExpansionPanelOrigin)`
  border-top: 1px solid #333;
  padding-top: 16px;
  margin-top: 0;
`;

export const Caption = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--font-color-secondary);
  margin: 15px 0;
`;

export const Header = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--font-color-secondary);
  margin-bottom: 14px;
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
`;

export const RightButtonWrapper = styled.div`
  width: 45px;
  justify-content: flex-end;
  display: flex;
  height: 48px;
`;
