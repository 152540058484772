import styled from 'styled-components';

export const MenuContent = styled.div`
  position: absolute;
  background-color: #eee;
  color: #333;
  z-index: 9999;
  border-radius: 4px;
`;

export const MenuItemSpan = styled.span`
  display: flex;
  padding: 10px;
`;

export const MenuLink = styled.a`
  text-decoration: none;
  color: inherit;
  display: flex;
  width: 100%;
  padding: 10px;

  &&:hover {
    color: inherit;
  }
`;
