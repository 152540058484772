import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UpgradePlanModal from '../../../../modals/UpgradePlanModal';

import { useTranslation } from '../../../../../i18n';
import { SwitchWithTitle } from '../../../../switch';

function HideBrandingSection({ allowHideBranding, hideBranding }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'HideBrandingSection',
  });
  const [isEnabled, setIsEnabled] = useState(hideBranding);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const hideModal = () => {
    setModalIsOpen(false);
  };

  const handleSwitch = () => {
    if (!allowHideBranding && !isEnabled) {
      setModalIsOpen(true);
    } else {
      setIsEnabled(!isEnabled);
    }
  };

  return (
    <>
      <UpgradePlanModal isOpen={modalIsOpen} hideModal={hideModal} />
      <SwitchWithTitle
        title={t('switchTitle')}
        checked={isEnabled}
        name="experience_settings_form_object[hide_branding]"
        data-testid="ExperienceSettings/hideBrandingSwitcher"
        onChange={handleSwitch}
      />
    </>
  );
}

HideBrandingSection.propTypes = {
  allowHideBranding: PropTypes.bool.isRequired,
  hideBranding: PropTypes.bool.isRequired,
};

export default HideBrandingSection;
