import React from 'react';
import _isEmpty from 'lodash/isEmpty';

export default function HiddenFields({ integrationSetups, currentSetup }) {
  if (!currentSetup) {
    return (
      <input
        type="hidden"
        name="event_action[integration_setups_attributes][0][integration_entity_id]"
        value=""
      />
    );
  }

  return (
    <>
      {integrationSetups.map((setup, setupIndex) => {
        if (currentSetup.id !== setup.id) {
          return (
            <React.Fragment key={setup.integration_entity.id}>
              {setup.id && (
                <input
                  type="hidden"
                  name={`event_action[integration_setups_attributes][${setupIndex}][id]`}
                  value={setup.id}
                />
              )}
              <input
                type="hidden"
                name={`event_action[integration_setups_attributes][${setupIndex}][active]`}
                value={false}
              />
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={setup.integration_entity.id}>
              {setup.id && (
                <input
                  type="hidden"
                  name={`event_action[integration_setups_attributes][${setupIndex}][id]`}
                  value={setup.id}
                />
              )}
              <input
                type="hidden"
                name={`event_action[integration_setups_attributes][${setupIndex}][active]`}
                value={currentSetup.id === setup.id}
              />
              <input
                type="hidden"
                name={`event_action[integration_setups_attributes][${setupIndex}][integration_entity_id]`}
                value={setup.integration_entity.id}
              />
              {setup.integration_mappings.map(
                ({ id, reference, integration_field, integration_mappable_type, integration_mappable_id, _destroy }, index) => {
                  if (!integration_mappable_id) {
                    return null;
                  } else if (_destroy) {
                    const key = `${integration_mappable_id}_${integration_mappable_type}`;
                    return (
                      <React.Fragment key={key}>
                        <input
                          type="hidden"
                          name={`event_action[integration_setups_attributes][${setupIndex}][integration_mappings_attributes][${index}][_destroy]`}
                          value="true"
                        />
                        <input
                          type="hidden"
                          name={`event_action[integration_setups_attributes][${setupIndex}][integration_mappings_attributes][${index}][id]`}
                          value={id}
                        />
                      </React.Fragment>
                    );
                  } else {
                    const key = `${integration_mappable_id}_${integration_mappable_type}`;
                    return (
                      <React.Fragment key={key}>
                        <input
                          type="hidden"
                          name={`event_action[integration_setups_attributes][${setupIndex}][integration_mappings_attributes][${index}][id]`}
                          value={id}
                        />
                        <input
                          type="hidden"
                          name={`event_action[integration_setups_attributes][${setupIndex}][integration_mappings_attributes][${index}][integration_mappable_id]`}
                          value={integration_mappable_id}
                        />
                        <input
                          type="hidden"
                          name={`event_action[integration_setups_attributes][${setupIndex}][integration_mappings_attributes][${index}][integration_mappable_type]`}
                          value={integration_mappable_type}
                        />
                        <input
                          type="hidden"
                          name={`event_action[integration_setups_attributes][${setupIndex}][integration_mappings_attributes][${index}][reference]`}
                          value={reference}
                        />
                      </React.Fragment>
                    );
                  }
                },
              )}
            </React.Fragment>
          );
        }
      })}
    </>
  );
}
