import React from 'react';
import TextField from '@mui/material/TextField';
import { ExpansionPanel } from '../MultipleFieldsSection/style';
import { Hint } from '../../../../globals';

export default function CustomCssSection({ customCss }) {
  const [value, setValue] = React.useState(customCss);

  return (
    <>
      <ExpansionPanel
        title="Custom CSS"
        isDefaultOpen={false}
        data-testid="ExperienceSettings/CustomCssSwitcher"
      >
        <Hint>Add styles to customize your Formli appearance.</Hint>
        <TextField
          sx={{
            '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--primary-color)',
            },
            width: '100%',
            '& .MuiOutlinedInput-input:focus': {
              boxShadow: 'none',
            },
          }}
          variant="outlined"
          multiline
          rows={15}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </ExpansionPanel>
      <input
        type="hidden"
        name="experience_settings_form_object[custom_css]"
        value={value}
      />
    </>
  );
}
