import React from 'react';
import { Context as BlockContext } from '../../../contexts/BlockContext';
import { Context as GoalAndMetricContext } from '../../../contexts/GoalAndMetricContext';
import { ReferencesProvider } from '../../../contexts/ReferencesContext';
import Preview from '../index';

function PreviewWithContext(props) {
  return (
    <BlockContext>
      <GoalAndMetricContext>
        <ReferencesProvider>
          <Preview {...props} />
        </ReferencesProvider>
      </GoalAndMetricContext>
    </BlockContext>
  );
}

export default PreviewWithContext;
