import React from 'react';

export default function VideoSettingsPlayButtonFirst() {
  return (
    <svg
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect width="104" height="104" rx="8" fill="var(--background-secondary)" />
        <rect
          x="26"
          y="16"
          width="52"
          height="72"
          rx="2.78571"
          fill="#333333"
          stroke="#BDBDBD"
          strokeWidth="0.928571"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          opacity="0.6"
          x="44"
          y="44"
          width="16"
          height="16"
          rx="8"
          fill="white"
        />
        <path
          d="M51 50.1035V53.8959C51 53.9759 51.0762 54.0259 51.1371 53.9859L53.9552 52.0897C54.0149 52.0497 54.0149 51.951 53.9552 51.911L51.1371 50.0148C51.0762 49.9735 51 50.0235 51 50.1035Z"
          fill="#333333"
        />
      </g>
    </svg>
  );
}
