import styled, { css, keyframes } from 'styled-components';

const fadeOutBorder = keyframes`
  from {
    border: 1px solid #fff;
    background: #4c4c4c;
  }
  to {
    border: 1px solid rgba(142, 142, 147, 0.5);
    background: #333;
  }
`;

const animation = props =>
  props.duplicated && css`
    ${fadeOutBorder} ease 3s 1;
`;

export const Thumbnail = styled.div`
  background-color: ${({ color }) => color};
`;

export const TitleRow = styled.div`
  margin-left: -0.5rem;
`;

export const Dragging = styled.li`
  border-left-color: ${({ isDragging }) => isDragging && '#39B34A'};
  border-left-width: ${({ isDragging }) => isDragging && '4px'};
  background-color: ${({ isDragging }) => isDragging && 'var(--background-primary)'};
  animation: ${animation};
`;

export const BlockContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 0.75rem 0.75rem 2.5rem;
  justify-content: flex-end;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
`;

export const LinkWithoutEffect = styled.a`
  &:hover,
  &:link,
  &:visited,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

export const DropdownMenuWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 12px;
  cursor: pointer;

  .AriaMenuButton-button {
    color: var(--font-color-secondary--light);
    transition: color 0.2s;
  }

  &:hover {
    .AriaMenuButton-button {
    color: var(--font-color-secondary--light);
    }
  }
`;
