import styled from 'styled-components';

export const Textarea = styled.textarea`
  background-color: var(--white);
  padding: 0.5rem;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 8px;
  border-top: 1px solid var(--border-color-primary);
  border-light: 1px solid var(--border-color-primary);
  border-right: 1px solid var(--border-color-primary);
  width: 100%;
  margin: 0;

  &:focus {
    background-color: var(--white);
    box-shadow: none;
  }
`;

export const ErrorMessage = styled.span`
  border-radius: 5px;
  text-align: center;
  background-color: var(--error);
  font-size: 12px;
  padding: 6px 12px;
  position: absolute;
  right: 10px;
  top: -15px;
`;

export const InputWrapper = styled.div`
  position: relative;
  textarea {
    min-height: 160px;
    border-radius: 8px 8px 0 0;
    &::placeholder {
      font-size: 14px;
      line-height: 22px;
      color: var(--font-color-secondary--dark);
      opacity: 0.3;
    }
  }

  button {
    margin: 0 0 1rem;
    border-radius: 0 0 8px 8px;
  }
`;
