import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { usePulling } from '../../../../../../hooks';
import OutboundSends from './components/OutboundSends';
import OutboundList from './components/OutboundList';
import {
  ChatbotLoadingButton,
  PrimaryLinkButton,
} from '../../../../../../Button';
import useWorkspace from '../../../../../../hooks/useWorkspace';

function ChatbotSettings({
  experienceUuid,
  chatbotAutoApprove,
  blacklistNumbers,
}) {
  const [chatbot, setChatbot] = React.useState(null);
  const [isPulling, setIsPulling] = React.useState(true);
  const [isNumbersSent, setIsNumbersSent] = React.useState(true);
  const pullChatbotPath = useMemo(
    () => `/api/v1/experiences/${experienceUuid}/chatbot`,
    [experienceUuid],
  );
  const workspaceId = useWorkspace();
  const primaryLinkButtonHref = `/${workspaceId}/settings/textbot`;

  const callback = (json) => {
    const {
      data: { attributes },
    } = json;
    setChatbot(attributes);

    setIsPulling(!attributes.isReady);
  };

  usePulling({
    url: pullChatbotPath,
    headers: { 'Workspace-Id': workspaceId },
    timeout: 1000,
    isPulling,
    callback,
  });

  if (!chatbot) {
    return null;
  }
  if (!chatbot.isReady) {
    return (
      <>
        <ChatbotLoadingButton
          data-testid="ChatbotSettings/creatingBot"
          isLoading
          disabled
        >
          Creating your bot...
        </ChatbotLoadingButton>
      </>
    );
  }

  return (
    <div data-testid="ExperienceSettings/chatbot">
      {chatbot.hasPhoneNumbers ? (
        <>
          <OutboundSends
            experienceUuid={experienceUuid}
            blacklistNumbers={blacklistNumbers}
            chatbotAutoApprove={chatbotAutoApprove}
            setIsNumbersSent={setIsNumbersSent}
          />
          <OutboundList
            experienceUuid={experienceUuid}
            isNumbersSent={isNumbersSent}
          />
        </>
      ) : (
        <PrimaryLinkButton
          href={primaryLinkButtonHref}
          target="_blank"
          data-testid="ChatbotSettings/generateNumbersButton"
        >
          Generate phone numbers
        </PrimaryLinkButton>
      )}
    </div>
  );
}

ChatbotSettings.propTypes = {
  experienceUuid: PropTypes.string.isRequired,
  chatbotAutoApprove: PropTypes.bool.isRequired,
  blacklistNumbers: PropTypes.arrayOf(PropTypes.string),
};

export default ChatbotSettings;
