import React from 'react';
import PropTypes from 'prop-types';

const attributesMapper = {
  UrlParamsSection: 'experience_url_params_attributes',
  KeywordsSection: 'chatbot_task_samples_attributes',
};

function HiddenFields({ fields, sectionName }) {
  return fields.map(({ name, id, deleted }, index) => (
    <div key={index}>
      <input
        name={`experience_settings_form_object[${attributesMapper[sectionName]}][${index}][name]`}
        value={name}
        type="hidden"
      />
      {id && (
        <>
          <input
            name={`experience_settings_form_object[${attributesMapper[sectionName]}][${index}][id]`}
            value={id}
            type="hidden"
          />
          <input
            name={`experience_settings_form_object[${attributesMapper[sectionName]}][${index}][_destroy]`}
            value={deleted}
            type="hidden"
          />
        </>
      )}
    </div>
  ));
}

HiddenFields.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
      deleted: PropTypes.bool,
    }),
  ).isRequired,
  sectionName: PropTypes.string.isRequired,
};

export default HiddenFields;
