import React from 'react';
import { SwitchWithTitle } from '../../../../switch';
import { Hint } from '../../../../globals';

export default function AuthenticationSection({ requiresAuthentication }) {
  const [isEnabled, setIsEnabled] = React.useState(requiresAuthentication);

  return (
    <SwitchWithTitle
      title="Requires authentication"
      checked={isEnabled}
      name="experience_settings_form_object[requires_authentication]"
      data-testid="ExperienceSettings/requiresAuthenticationSwitcher"
      onChange={() => setIsEnabled(!isEnabled)}
    >
      <Hint>Your responders will have to create an account and log in to be able to fill out your form.</Hint>
    </SwitchWithTitle>
  );
}
