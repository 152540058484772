import React from 'react';
import {
  Form,
  ButtonsWrapper,
  CancelButtonWrapper,
} from './style';
import { Button, PrimaryButton, NewItemButton } from '../../../Button';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import TransitionRule from './components/TransitionRule';
import MultipleChoiceCondition from './components/MultipleChoiceCondition';
import { Label } from '../../../globals';
import { getSelectDefaultValue } from '../../TransitionButton/utils';
import ConditionsSelect from '../ConditionsSelect';
import { REDIRECT_TRANSITION, GO_TO_TRANSITION } from '../../../../constants';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _isEmpty from 'lodash/isEmpty';
import { nanoid } from 'nanoid';

const initialSubTransition = {
  position: 1,
  transition_type: 'next_transition',
  to_block_id: null,
  redirect_url: null,
  // Unique id needed for dnd reorder. We're removing it from params on form save
  nanoid: nanoid(),
  conditions_attributes: [
    {
      first_value: '',
      second_value: '',
      operator: 'equals',
      data_type: 'string',
    },
  ],
};

function ConditionalTransitionForm({
  visible,
  experienceId,
  selectOptions,
  experienceUrlMentionSteps,
  mcOptions,
  subTransitions,
  initialElseTransition,
  blockUrlMentionSteps,
  onCancel,
  onSave,
  errors,
}) {
  if (!visible) {
    return null;
  }
  const initialSubTransitions = React.useMemo(() => {
    return _isEmpty(subTransitions)
      ? [initialSubTransition]
      : subTransitions.map(
          (
            {
              attributes: {
                transition_type,
                transitionable_type,
                transitionable_id,
                to_block_id,
                redirect_url,
                conditions,
                id,
              },
            },
            index,
          ) => {
            return {
              id,
              position: index + 1,
              transition_type,
              transitionable_type,
              transitionable_id,
              to_block_id,
              redirect_url,
              conditions_attributes: conditions.data.map((condition) => ({
                id: condition.attributes.id,
                first_value: condition.attributes.firstValue,
                second_value: condition.attributes.secondValue,
                operator: condition.attributes.operator,
                data_type: condition.attributes.dataType,
              })),
            };
          },
      );
  }, [subTransitions]);

  const [transitions, setTransitions] = React.useState(initialSubTransitions);
  const [elseTransition, setElseTransition] = React.useState(
    initialElseTransition,
  );
  const isRedirectOptionSelected =
    elseTransition.transition_type === REDIRECT_TRANSITION;
  const isGoToOptionSelected =
    elseTransition.transition_type === GO_TO_TRANSITION;

  const handleElseSelectChange = (option) => {
    setElseTransition((prev) => ({
      ...prev,
      transition_type: option.value,
      redirect_url: '',
      to_block_id: option.data?.to_block_id,
    }));
  };

  const handleElseSelectedConditionChange = (value) => {
    setElseTransition((prev) => ({
      ...prev,
      redirect_url: value,
    }));
  };

  const handleAddRule = () => {
    setTransitions((prev) => [
      ...prev,
      { ...initialSubTransition, nanoid: nanoid(), position: prev.length + 1 },
    ]);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((item, i) => ({ ...item, position: i + 1 }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      transitions,
      result.source.index,
      result.destination.index,
    );

    setTransitions(newItems);
  };

  const handleSave = () => {
    onSave(transitions, elseTransition);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <Form>
      <Box sx={{ mt: 2 }}>
        <Label color="var(--font-color-primary)">Conditional Transition</Label>
        <FormHelperText sx={{ mb: 2 }}>
          Dynamically change your form flow depending on your responder’s
          answers!
        </FormHelperText>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="condition_rule">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {transitions.map((transition, index) => (
                <Draggable
                  key={transition.id || transition.nanoid}
                  draggableId={
                    transition.id ? transition.id.toString() : transition.nanoid
                  }
                  index={index}
                >
                  {(provided) => (
                    <Box
                      sx={{ position: 'relative' }}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <Box
                        sx={{
                          display: transition._destroy ? 'none' : 'block',
                          position: 'absolute',
                          left: -9,
                          top: 12,
                        }}
                        aria-label="transition rule drag handle"
                        {...provided.dragHandleProps}
                      >
                        <DragIndicatorIcon
                          sx={{ color: '#757575' }}
                          fontSize="small"
                        />
                      </Box>
                      <Box sx={{ ml: 2, mr: -1.5 }}>
                        {transition.transitionable_type ===
                        'MultipleChoiceOption' ? (
                            <MultipleChoiceCondition
                              ruleIndex={index}
                              optionName={mcOptions.find((o) => o.id === transition.transitionable_id)?.name}
                              transition={transition}
                              selectOptions={selectOptions}
                              experienceId={experienceId}
                              experienceUrlMentionSteps={
                                experienceUrlMentionSteps
                              }
                              setTransitions={setTransitions}
                              onKeyDown={handleKeyDown}
                              blockUrlMentionSteps={blockUrlMentionSteps}
                              errors={errors.sub_transitions || {}}
                            />
                          ) : (
                            <TransitionRule
                              ruleIndex={index}
                              experienceId={experienceId}
                              selectOptions={selectOptions}
                              transition={transition}
                              setTransitions={setTransitions}
                              onKeyDown={handleKeyDown}
                              experienceUrlMentionSteps={
                                experienceUrlMentionSteps
                              }
                              blockUrlMentionSteps={blockUrlMentionSteps}
                              errors={errors.sub_transitions || {}}
                            />
                          )}
                      </Box>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <NewItemButton onClick={handleAddRule}>+ Add rule</NewItemButton>
      <ConditionsSelect
        selectProps={{
          options: selectOptions,
          defaultValue: getSelectDefaultValue(elseTransition, selectOptions),
          onChange: handleElseSelectChange,
          name: 'else_transition',
          id: 'else_transition',
          startLabel: 'Else',
        }}
        conditionProps={{
          name: 'redirect_url_else',
          redirectMentionSteps: experienceUrlMentionSteps,
          goToMentionSteps: blockUrlMentionSteps,
          experienceId,
          onValueChange: handleElseSelectedConditionChange,
          onKeyDown: handleKeyDown,
          initialValue: elseTransition.redirect_url || '',
          autoFocus: !elseTransition.redirect_url,
          error: errors?.redirect_url_skip,
        }}
        isRedirectOptionSelected={isRedirectOptionSelected}
        isGoToOptionSelected={isGoToOptionSelected}
      />
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button onClick={onCancel}>Cancel</Button>
        </CancelButtonWrapper>
        <PrimaryButton onClick={handleSave}>
          Save Changes
        </PrimaryButton>
      </ButtonsWrapper>
    </Form>
  );
}

export default ConditionalTransitionForm;
