import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding: 8px 16px 8px 8px;
  margin-bottom: 8px;
  background-color: ${(props) =>
    props.cardIsOpen ? 'var(--surface-background-secondary--dark)' : 'transparent'};
  border: 1px solid var(--border-color-secondary);
  border-radius: 8px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    transition: color 0.1s;
  }

  &:hover {
    svg {
      color: var(--font-color-primary);
    }
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export const Label = styled.div`
  font-size: 14px;
  color: var(--font-color-primary);
`;

export const IconWrapperWithBackground = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.background};
  border-radius: 4px;
  margin-right: 12px;
  flex-shrink: 0;
`;

export const IconWrapperWithMargin = styled.div`
  margin-right: 1rem;
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  overflow: hidden;
  margin-left: 44px;
`;

export const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const Subtitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: var(--font-color-secondary);
  line-height: 1.33;
  font-weight: 500;
  text-transform: uppercase;
`;

export const ArrowIconWrapper = styled.div`
  display: flex;
  margin-left: 6px;
  color: var(--font-color-secondary);
`;
