import styled from 'styled-components';

export const PillButtonWrapper = styled.div`
  text-align: center;
  margin-top: -38px;
`;

export const PillButton = styled.div`
  position: relative;
  background-color: ${(props) =>
    props.disabled ? 'var(--font-color-secondary--light)' : 'var(--primary-color)'};
  padding: 1px 12px;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  font-size: 14px;
  line-height: 1.15;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  text-transform: capitalize;
  outline: none;
  z-index: 1;
`;

export const ButtonTitle = styled.div`
  padding: 2px;
  align-items: flex-start;
  color: var(--white);
`;

export const ButtonIcon = styled.div`
  margin-top: 3px;
  margin-left: 5px;
  color: var(--white);

  svg {
    stroke-width: 3;
  }
`;

export const DisabledPill = styled.div`
  background-color: gray;
  color: var(--font-color-light-primary);
`;
