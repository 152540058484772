import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from '../../../../../i18n';
import { NewItemButton } from '../../../../Button';
import {
  ExpansionPanel,
  Caption,
  Header,
  InputWrapper,
  RightButtonWrapper,
} from './style';
import { reducer, initialState } from './reducer';
import InputField from './components/InputField';
import HiddenFields from './components/HiddenFields';

function MultipleFieldsSection({
  defaultFields,
  sectionName,
  panelTitle,
  header,
  buttonText,
  inputMaxLength,
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: sectionName,
  });
  const [state, dispatch] = React.useReducer(
    reducer,
    initialState(defaultFields),
  );
  const { fields, withDelete } = state;
  const isDefaultOpen = React.useMemo(() => {
    return fields.filter((p) => p.errors && p.errors.name).length > 0;
  }, []);

  const handleChange = (index) => (e) => {
    dispatch({
      type: 'changeItemName',
      payload: { index, name: e.target.value },
    });
  };
  const handleDelete = (index) => () => {
    dispatch({ type: 'deleteItem', payload: { index } });
  };
  const handleAddNewInput = () => {
    dispatch({ type: 'addNewItem' });
  };

  return (
    <ExpansionPanel
      title={panelTitle}
      isDefaultOpen={isDefaultOpen}
      data-testid={`ExperienceSettings/${sectionName}Switcher`}
    >
      <div data-testid={`ExperienceSettings/${sectionName}`}>
        <Caption>{t('caption')}</Caption>
        <Header>{header}</Header>
        {fields.map(({ name, deleted, errors }, index) => (
          <InputField
            key={index}
            onChange={handleChange(index)}
            onDelete={handleDelete(index)}
            onEnter={handleAddNewInput}
            errors={errors}
            name={name}
            deleted={deleted}
            index={index}
            withDelete={withDelete}
            maxLength={inputMaxLength}
            sectionName={sectionName}
          />
        ))}
        <InputWrapper>
          <NewItemButton
            noMargin={true}
            onClick={handleAddNewInput}
            data-testid={`${sectionName}/NewItemButton`}
          >
            {buttonText}
          </NewItemButton>
          <RightButtonWrapper />
        </InputWrapper>
        <HiddenFields fields={fields} sectionName={sectionName} />
      </div>
    </ExpansionPanel>
  );
}

MultipleFieldsSection.propTypes = {
  defaultFields: PropTypes.array.isRequired,
  sectionName: PropTypes.string.isRequired,
  panelTitle: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  inputMaxLength: PropTypes.number,
};

export default MultipleFieldsSection;
