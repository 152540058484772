import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { StyledErrorMessage } from '../Input/style';

const styles = {
  input: {
    width: 138,
    pr: '18px',
    borderRadius: '8px',
    '&.Mui-error .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid var(--border-color-primary)',
    },
    '& .MuiInputBase-input': {
      textTransform: 'uppercase',
      '&:focus': {
        boxShadow: 'none',
      },
    },
  },
  adornment: {
    '&.MuiInputAdornment-root': {
      width: 32,
      '& .MuiButtonBase-root': {
        width: 'auto',
        color: '#333',
      },
      '& .MuiButtonBase-root:hover': {
        color: '#333',
      },
    },
  },
  paper: {
    '& button': {
      width: 'auto',
    },
    '& span:focus, & span:focus-visible, & span:focus-within': {
      outline: '0 !important',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0) !important',
      '-webkit-touch-callout': 'none',
      '-webkit-user-select': 'none',
      '-khtml-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
    },
    '& .MuiPickersArrowSwitcher-root + div > button:hover': {
      background: '#ff4200',
    },
    '& .MuiPickersArrowSwitcher-root button:hover': {
      color: 'inherit',
    },
  },
};

// Hardcoding color because MUI team still working on ability to customize styles and do not support css variables
const theme = createTheme({
  palette: { primary: { main: '#ff4200' } },
});

function TimeSelector({ value, name }) {
  const [time, setTime] = useState(value);
  const [error, setError] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <div style={{ width: '138px', position: 'relative' }}>
          {error && <StyledErrorMessage>invalid format</StyledErrorMessage>}
          <DesktopTimePicker
            value={time}
            onChange={setTime}
            onError={setError}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: '08:00 AM',
                }}
              />
            )}
            InputProps={{
              'data-testid': 'TimeSelector/InputWrapper',
              sx: styles.input,
            }}
            InputAdornmentProps={{ sx: styles.adornment }}
            PaperProps={{ sx: styles.paper }}
          />
        </div>
      </ThemeProvider>
      <input type="hidden" name={name} defaultValue={time || ''} />
    </LocalizationProvider>
  );
}

TimeSelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  name: PropTypes.string,
};

export default TimeSelector;
