import React from 'react';

class ManualChatbotApproveOkMessage {
  show(workspaceId) {
    return <div dangerouslySetInnerHTML={{__html: this._message(workspaceId) }} />;
  }

  _message(workspaceId) {
    const txt  = 'Your texts are being processed and will be ready in a ' +
                 'few minutes. They can be sent from the ' +
                 "<a href='/" + workspaceId + "/inbox'>Inbox.</a>";
    return txt;
  }
}

export default ManualChatbotApproveOkMessage;
