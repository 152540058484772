import React from 'react';
import { EditableMentionsTextarea } from '../../../../../../../SlateElements';
import { Label, Fieldset, ErrorMessage } from '../../../../../../../globals';
import { ErrorMessageWrapper } from '../../../../style';
import { getErrorMessage } from '../../../../../../../../utils';
import SimpleSelect from '../../../../../../../select';
import EmailBuilder from '../EmailBuilder';
import useEmailBuilder from '../EmailBuilder/reducer';

const TEMPLATE_EMAIL_BODY = 'template';
const HTML_EMAIL_BODY = 'html';

const emailBodyTypes = [
  { value: TEMPLATE_EMAIL_BODY, label: 'Build email' },
  { value: HTML_EMAIL_BODY, label: 'Copy in HTML email code' },
];

export default function EmailBuilderSection({
  isNew,
  customEmail,
  emailBody,
  experienceId,
  mentionSteps,
  errors,
}) {
  const [emailBodyType, setEmailBodyType] = React.useState(
    isNew || (customEmail && customEmail.active)
      ? TEMPLATE_EMAIL_BODY
      : HTML_EMAIL_BODY,
  );
  const onEmailBodyTypeChange = (event) => {
    setEmailBodyType(event.value);
  };

  const {
    email,
    handleInitCustomEmail,
    handleSaveTemplate,
    handleChooseTemplate,
    handleDeleteEmail,
    handleSetEmailActiveState,
  } = useEmailBuilder(
    customEmail,
    getErrorMessage(errors, 'custom_email.email_template.body'),
  );

  React.useEffect(() => {
    handleSetEmailActiveState(emailBodyType === TEMPLATE_EMAIL_BODY);
  }, [emailBodyType]);

  return (
    <Fieldset
      style={{ position: 'relative' }}
      data-testid="EmailActionForm/EmailBodySection"
    >
      <Label>Email body</Label>
      <SimpleSelect
        options={emailBodyTypes}
        value={emailBodyTypes.find((type) => type.value === emailBodyType)}
        onChange={onEmailBodyTypeChange}
        placeholder="Select one option"
      />
      {emailBodyType === TEMPLATE_EMAIL_BODY && (
        <EmailBuilder
          handleInitCustomEmail={handleInitCustomEmail}
          handleSaveTemplate={handleSaveTemplate}
          handleChooseTemplate={handleChooseTemplate}
          handleDeleteEmail={handleDeleteEmail}
          email={email}
        />
      )}
      {emailBodyType === HTML_EMAIL_BODY && (
        <div style={{ position: 'relative' }}>
          <EditableMentionsTextarea
            name="event_action[email_body]"
            placeholder="Paste in your HTML email code"
            initialValue={emailBody || ''}
            experienceId={experienceId}
            steps={mentionSteps}
            error={getErrorMessage(errors, 'email_body')}
          />
          {getErrorMessage(errors, 'email_body') && (
            <ErrorMessageWrapper>
              <ErrorMessage>
                {getErrorMessage(errors, 'email_body')}
              </ErrorMessage>
            </ErrorMessageWrapper>
          )}
        </div>
      )}
      <HiddenInputs
        isNew={isNew}
        customEmail={customEmail}
        emailBodyType={emailBodyType}
        email={email}
      />
    </Fieldset>
  );
}

function HiddenInputs({ isNew, emailBodyType, customEmail, email }) {
  return (
    <>
      {customEmail && customEmail.id && (
        <>
          <input
            type="hidden"
            name="event_action[custom_email_attributes][id]"
            value={customEmail.id}
          />
          <input
            type="hidden"
            name="event_action[custom_email_attributes][active]"
            value={emailBodyType === TEMPLATE_EMAIL_BODY}
          />
        </>
      )}
      {(isNew ? emailBodyType === TEMPLATE_EMAIL_BODY : email.active) && (
        <>
          <input
            type="hidden"
            name="event_action[custom_email_attributes][email_template_attributes][body]"
            value={email.unlayerJsonData && email.unlayerJsonData.html}
          />
          <input
            type="hidden"
            name="event_action[custom_email_attributes][email_template_attributes][unlayer_json_data]"
            value={JSON.stringify(email.unlayerJsonData)}
          />
        </>
      )}
    </>
  );
}

