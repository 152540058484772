import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  duplicateBlock as duplicateBlockRequest,
  reorderBlocks,
  deleteBlock as deleteBlockRequest,
} from '../../api';
import BlockListItem from '../BlockListItem';
import { reorderArrayByIndexes } from '../../../utils';

const checkIfClientAndServerMatched = (clientItems, serverItems) => {
  return clientItems.every(
    (currentItem, index) => currentItem.id === serverItems[index].id,
  );
};

const extractItemsFromResponse = (response) =>
  response.data.map((item) => item.attributes);

const DragAndDropBlockList = ({
  items: defaultItems,
  experienceUuid,
  experienceId,
  workspaceId,
}) => {
  const [items, setItems] = useState(defaultItems);
  const onDragEnd = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination || destination.index === source.index) {
      return;
    }

    const requestParams = { position: result.destination.index + 1 };
    const reorderedItems = reorderArrayByIndexes(
      items,
      source.index,
      destination.index,
    );

    setItems(reorderedItems);

    reorderBlocks(experienceUuid, result.draggableId, requestParams).then(
      (response) => {
        const serverItems = extractItemsFromResponse(response);
        const isClientAndServerMatched = checkIfClientAndServerMatched(
          reorderedItems,
          serverItems,
        );

        if (!isClientAndServerMatched) {
          window.exceptionHandler.notify(
            new Error(
              "DragAndDropBlockList component: Client side and server side reordered blocks doesn't matched",
            ),
          );
          window.location.reload();
        }
      },
    );
  };

  const deleteBlock = (blockId, withReload) => {
    deleteBlockRequest(experienceUuid, blockId).then((response) => {
      if (response.status === 200) {
        if (withReload) {
          location.reload();
        } else {
          const newItems = items.filter((e) => e.id !== blockId);
          setItems(newItems);
        }
      }
    });
  };

  const scrollToDuplicatedBlock = () => {
    const blocksContainer = document.querySelector('#askEditBlocks');
    const blockHeight =
      document.querySelector('.experience_show__block-list-item').offsetHeight +
      80;

    if (blocksContainer) {
      blocksContainer.scrollBy({ top: blockHeight, behavior: 'smooth' });
    } else {
      window.scrollBy({ top: blockHeight, behavior: 'smooth' });
    }
  };

  const duplicateBlock = (id) => {
    duplicateBlockRequest(experienceUuid, id).then((response) => {
      setItems(extractItemsFromResponse(response));
      scrollToDuplicatedBlock();
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <BlockListItem
                key={item.id}
                index={index}
                deleteBlock={deleteBlock}
                duplicateBlock={duplicateBlock}
                experienceUuid={experienceUuid}
                experienceId={experienceId}
                workspaceId={workspaceId}
                experienceBlocks={items}
                {...item}
                {...provided}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DragAndDropBlockList.propTypes = {
  items: PropTypes.array,
  experienceUuid: PropTypes.string,
  experienceId: PropTypes.number,
};
export default DragAndDropBlockList;
