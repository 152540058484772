import React from 'react';
import PropTypes from 'prop-types';
import { Container, DisclaimerWrapper, InnerContainer } from './style';
import DataFieldsSelector from '../../../responder/DataFieldsSelector';
import Disclaimer from '../Disclaimer';
import { generateDisclaimerPreviewText } from '../../../BlockForm/components/Disclaimer';

function DataFields({ dataFields, disclaimerChecked, disclaimer, contentLayout, isPreview }) {
  return (
    <Container contentLayout={contentLayout}>
      <InnerContainer>
        <DataFieldsSelector
          fields={dataFields}
          responses={[]}
          currency="usd"
          disabledFields
          isPreview={isPreview}
        />
        {disclaimerChecked && disclaimer && (
          <DisclaimerWrapper>
            <Disclaimer
              label={generateDisclaimerPreviewText(
                disclaimer.tocLabel,
                disclaimer.url,
              )}
            />
          </DisclaimerWrapper>
        )}
      </InnerContainer>
    </Container>
  );
}

DataFields.propTypes = {
  dataFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      required: PropTypes.bool,
      field_type: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  disclaimerChecked: PropTypes.bool,
  disclaimer: PropTypes.shape({
    tocLabel: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default DataFields;
