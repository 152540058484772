import styled from 'styled-components';

import ExpansionPanelOrigin from '../../../../expansionPanel';

export const SettingsFieldset = styled.fieldset`
  margin-top: 0;
  margin-bottom: 0;
  .checkbox__input:checked + label {
    border-radius: 8px 8px 8px 8px;
  }

  &:first-child {
    padding-top: 1rem;
  }
`;

export const ExpansionPanel = styled(ExpansionPanelOrigin)`
  border-top: 1px solid #333;
  padding-top: 16px;
  margin-top: 0;
`;
