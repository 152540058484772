import styled from 'styled-components';

export const MetricItemWrapper = styled.div`
  background-color: var(--surface-bakground);
  border: ${(props) =>
    props.isChecked
      ? '1px solid var(--primary-color)'
      : '1px solid var(--border-color-primary)'};
  padding: 10px 15px;
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: left;
`;
