import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { EventCard } from '../EventCard';
import { ReferencesProvider } from '../../../../contexts/ReferencesContext';
import withSnackbarProvider from '../../../shared/hoc/withSnackbarProvider';
import { useSnackbar } from 'notistack';

function EventsList({ list, successMessage }) {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (successMessage) {
      enqueueSnackbar(successMessage, { variant: 'success' });
    }
  }, []);

  return (
    <ReferencesProvider>
      {list.map(({ attributes, links }) => {
        return (
          <EventCard
            key={attributes.uuid}
            newActionUrl={links.newActionUrl}
            attributes={attributes}
            deleteUrl={links.deleteUrl}
          />
        );
      })}
    </ReferencesProvider>
  );
};

EventsList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        eventType: PropTypes.string.isRequired,
      }),
      links: PropTypes.shape({
        newActionUrl: PropTypes.string.isRequired,
        deleteUrl: PropTypes.string.isRequired,
      }),
    }),
  ),
  successMessage: PropTypes.string,
};

export default withSnackbarProvider(EventsList);
