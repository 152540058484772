import styled from 'styled-components';

export const BackgroundContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const MediaContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: ${(props) =>
    props.backgroundLayout === 'fit_to_size' ? 'contain' : 'cover'};
  object-position: center;
`;

export const ColorOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;
