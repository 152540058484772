import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SwitchWithTitle } from '../../../../switch';

function SocialShareSection({ socialShareEnabled}) {
  const [isEnabled, setIsEnabled] = useState(socialShareEnabled);

  return (
    <SwitchWithTitle
      title="Add social share"
      checked={isEnabled}
      name="experience_settings_form_object[social_share_enabled]"
      data-testid="ExperienceSettings/addSocialShareSwitcher"
      onChange={() => setIsEnabled(!isEnabled)}
    />
  );
};

SocialShareSection.propTypes = {
  socialShareEnabled: PropTypes.bool.isRequired,
};

export default SocialShareSection;
