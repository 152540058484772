import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import EmailEditor from '../../shared/EmailEditor';
import Header from './components/Header';
import Footer from './components/Footer';
import EventActionContext from '../../../contexts/EventActionContext';
import useWorkspace from '../../hooks/useWorkspace';
import { PrimaryButton } from '../../Button';

function EmailBuilderModal({
  isOpen,
  hideModal,
  customEmail,
  handleSaveTemplate,
  handleInitCustomEmail,
}) {
  const emailEditorRef = React.useRef(null);
  const workspaceId = useWorkspace();
  const {
    experienceId,
    emailBuilder: { init, customJsUrls, auth },
  } = useContext(EventActionContext);

  const handleSave = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      handleSaveTemplate(data);
      hideModal();
    });
  };

  const onReady = () => {
    if (customEmail) {
      emailEditorRef.current.loadDesign(customEmail.design);
    }
  };

  const handleCancel = () => {
    handleInitCustomEmail();
    hideModal();
  };

  return (
    <ReactModal
      id="editor-container"
      isOpen={isOpen}
      onRequestClose={handleCancel}
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: 1,
        },
      }}
    >
      <Header title="Build your email" onCancel={handleCancel} />
      <EmailEditor
        init={init}
        emailEditorRef={emailEditorRef}
        onReady={onReady}
        customJS={[
          `window.gon = ${JSON.stringify(window.gon)};`,
          `window.haHost = "${window.location.protocol}//${window.location.host}";`,
          `window.haAuth = ${JSON.stringify(auth)};`,
          `window.haExpId = ${experienceId};`,
          `window.haWorkspaceId = ${workspaceId};`,
          ...customJsUrls,
        ]}
      />
      <Footer>
        <PrimaryButton
          style={{ width: '400px', margin: 0 }}
          onClick={handleSave}
        >
          Save email
        </PrimaryButton>
      </Footer>
    </ReactModal>
  );
}

EmailBuilderModal.propTypes = {
  isOpen: PropTypes.bool,
  hideModal: PropTypes.func,
  title: PropTypes.string,
  design: PropTypes.shape({}),
};

export default EmailBuilderModal;
