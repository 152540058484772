import React from 'react';
import PropTypes from 'prop-types';
import { MediaContainer, Image } from '../../style';
import VideoBackground from '../../../../../responder/videoplayer/VideoBackground';

function MediaBackground({
  imageUrl,
  videoUrl,
  setVideoIsPlaying,
  onPlayButtonClick,
  withShareIcon,
  backgroundLayout,
  hidePlayButton,
}) {

  return (
    <MediaContainer>
      {imageUrl && (
        <Image
          data-testid="Preview/BackgroundImage"
          src={imageUrl}
          backgroundLayout={backgroundLayout}
        />
      )}
      {videoUrl && (
        <div data-testid="VideoBackground/Background">
          <VideoBackground
            sources={[videoUrl]}
            onVideoPlaying={setVideoIsPlaying}
            onPlayButtonClick={onPlayButtonClick}
            backgroundLayout={backgroundLayout}
            withShareIcon={withShareIcon}
            hidePlayButton={hidePlayButton}
          />
        </div>
      )}
    </MediaContainer>
  );
}

MediaBackground.propTypes = {
  setVideoIsPlaying: PropTypes.func,
  onPlayButtonClick: PropTypes.func,
  backgroundLayout: PropTypes.string,
  imageUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  withShareIcon: PropTypes.bool,
};

export default MediaBackground;
