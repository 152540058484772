import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  HLine,
  FlexRowSpaceBetween,
  FlexCol,
} from '../../../../../../../../../globals';

export const ContentCol = styled(FlexCol)`
  width: 70%;
`;

function OutboundListItem({ list }) {
  return (
    <>
      <FlexRowSpaceBetween>
        <FlexCol>{list.createdAt}</FlexCol>
        <ContentCol onClick={() => setIsOpen(!isOpen)}>
          <FlexRowSpaceBetween>
            <FlexCol>{list.numbersCount} recepients</FlexCol>
          </FlexRowSpaceBetween>
        </ContentCol>
      </FlexRowSpaceBetween>
      <HLine />
    </>
  );
}

OutboundListItem.propTypes = {
  list: PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    numbersCount: PropTypes.number,
  }).isRequired,
};

export default OutboundListItem;
