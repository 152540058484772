import React from 'react';
import PropTypes from 'prop-types';
import { Title } from './style';
import MentionTitle from '../MentionTitle';
import { ReferencesProvider } from '../../contexts/ReferencesContext';

function ExperienceTitle({ title }) {
  return (
    <ReferencesProvider>
      <Title>
        <MentionTitle title={title} />
      </Title>
    </ReferencesProvider>
  );
}

ExperienceTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ExperienceTitle;
