import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import Modal from '../../modal';
import { maybePluralize } from '../../../utils';
import DragIcon from '../../icons/DragIcon';
import TransitionButton from '../TransitionButton';
import Dropdown from '../../dropdown';
import {
  Dragging,
  BlockContainer,
  LinkWithoutEffect,
  Thumbnail,
  TitleRow,
  DropdownMenuWrapper,
} from './style';
import { MoreHorizontal, Copy, Trash } from 'react-feather';
import MentionTitle from '../../MentionTitle';

const menuItems = [
  {
    title: 'Duplicate',
    value: 'duplicate',
    icon: <Copy />,
  },
  {
    title: 'Delete',
    value: 'delete',
    icon: <Trash />,
  },
];

function BlockListItem({
  index,
  id,
  experienceId,
  title,
  blockType,
  displayBlockType,
  backgroundColor,
  blockThumbnailImageUrl,
  deleteBlock,
  duplicateBlock,
  duplicated,
  experienceUuid,
  workspaceId,
  askUuid,
  responsesCount,
  transition,
  multipleChoiceOptions,
  experienceBlocks,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const blockRef = useRef();
  const editBlockUrl = `/${workspaceId}/experiences/${experienceUuid}/asks/${askUuid}/edit`;

  useEffect(() => {
    if (window.location.href.includes(`asks/${askUuid}`)) {
      setIsActive(true);
    }
  }, []);

  const handleDeleteBlock = (e) => {
    e.stopPropagation();
    deleteBlock(id, isActive);

    setIsOpen(false);
  };

  const handleCancelDeleteBlock = (e) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const showModal = () => {
    setIsOpen(true);
  };

  const handleDropdownSelect = (value) => {
    switch (value) {
      case 'duplicate':
        duplicateBlock(id);
        break;
      case 'delete':
        showModal();
        break;
    }
  };

  const renderModal = () => {
    const modalButtons = [
      { title: 'Cancel', onClick: handleCancelDeleteBlock },
      { title: 'Delete', onClick: handleDeleteBlock, primary: true },
    ];

    return (
      <Modal
        isOpen={isOpen}
        title="Confirm"
        content="Are you sure you want to delete this block?"
        buttons={modalButtons}
        hideModal={hideModal}
      />
    );
  };

  return (
    <Draggable key={id.toString()} draggableId={id.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          className="block-element"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Dragging
            isDragging={snapshot.isDragging}
            duplicated={duplicated}
            className={`experience_show__block-list-item ${
              isActive ? 'active' : ''
            }`}
          >
            <BlockContainer ref={blockRef}>
              <div className="left-column">
                <TitleRow className="row" {...provided.dragHandleProps}>
                  <div>
                    <DragIcon fill="var(--font-color-secondary)" />
                  </div>
                  <div className="experience_show__block-list-item-label-wrapper">
                    <div className="experience_show__block-list-item-label">
                      {index + 1} - {displayBlockType}
                    </div>
                  </div>
                </TitleRow>

                <LinkWithoutEffect href={editBlockUrl}>
                  <div className="row">
                    <div className="experience-block__thumbnail-wrapper">
                      <Thumbnail
                        className="experience_show__block-thumbnail"
                        color={backgroundColor}
                      >
                        {blockThumbnailImageUrl && (
                          <img src={blockThumbnailImageUrl} />
                        )}
                      </Thumbnail>
                    </div>

                    <div className="experience-block__content-wrapper">
                      <div className="experience_show__block-list-item-title">
                        <MentionTitle title={title} />
                      </div>
                      <div className="experience-block__subtitle">
                        {maybePluralize(
                          responsesCount,
                          'response',
                        ).toUpperCase()}
                      </div>
                    </div>
                  </div>
                </LinkWithoutEffect>
                <DropdownMenuWrapper>
                  <Dropdown
                    menuItems={menuItems}
                    actionButton={
                      <MoreHorizontal data-testid="BlockListItem/DropdownButton" />
                    }
                    onSelection={handleDropdownSelect}
                    listStyle={{ left: '-132px', top: '0', width: '160px' }}
                    wrapperClassName="blocklist-dropdown"
                  />
                </DropdownMenuWrapper>
              </div>
            </BlockContainer>
          </Dragging>
          {isOpen && renderModal()}
          <TransitionButton
            blockId={id}
            experienceId={experienceId}
            transitionObject={transition}
            experienceBlocks={experienceBlocks}
            multipleChoiceOptions={multipleChoiceOptions}
            blockType={blockType}
            blockRef={blockRef}
          />
        </div>
      )}
    </Draggable>
  );
}

BlockListItem.propTypes = {
  title: PropTypes.string,
  blockType: PropTypes.string,
  index: PropTypes.number.isRequired,
  displayBlockType: PropTypes.string,
  responsesCount: PropTypes.number,
  isDragging: PropTypes.bool,
  backgroundColor: PropTypes.string,
  blockThumbnailImageUrl: PropTypes.string,
  experienceUuid: PropTypes.string,
  experienceId: PropTypes.number,
  askUuid: PropTypes.string,
  transition: PropTypes.object,
  multipleChoiceOptions: PropTypes.array,
  experienceBlocks: PropTypes.array,
};

export default BlockListItem;
