import styled from 'styled-components';

export const ActionButton = styled.button`
  @media (min-width: 600px) {
    width: 290px;
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
`;
