import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SwitchWithTitle } from '../../../../switch';

function ProgressBarSection({ showProgressBar }) {
  const [isEnabled, setIsEnabled] = useState(showProgressBar);

  return (
    <SwitchWithTitle
      title="Show progress bar"
      checked={isEnabled}
      name="experience_settings_form_object[show_progress_bar]"
      data-testid="ExperienceSettings/showProgressBarSwitcher"
      onChange={() => setIsEnabled(!isEnabled)}
    />
  );
};

ProgressBarSection.propTypes = {
  showProgressBar: PropTypes.bool.isRequired,
};

export default ProgressBarSection;
