import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import BlockContext from '../../../../contexts/BlockContext';
import api from '../../../api-client';
import Block from '../Block';
import { Container } from './style';
import { setValueToStore } from '../../../../sharedStore';

function Experience({ workspace_id, experience_uuid, ask_uuid, preview, appSettings }) {
  const [loading, setLoading] = useState(true);
  const [, setBlockState] = useContext(BlockContext);
  const [experienceSettings, setExperienceSettings] = useState({});

  useEffect(() => {
    api.show('asks', { id: ask_uuid }).then((response) => {
      const attributes = response.data.data.attributes;
      setBlockState((prevState) => ({
        ...prevState,
        ...attributes,
      }));
      setLoading(false);
      // To prevent bug with wrong block settings on preview after invalid form save
      setValueToStore('updateBlockContext', true);
    });

    api
      .get('experiences/:uuid/settings', { uuid: experience_uuid })
      .then((response) => {
        setExperienceSettings(response.data.data.attributes);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <Container>
        <Block
          preview={preview}
          workspace_id={workspace_id}
          ask_uuid={ask_uuid}
          experienceSettings={experienceSettings}
          appSettings={appSettings}
        />
      </Container>
    );
  }
};

Experience.propTypes = {
  workspace_id: PropTypes.number,
  experience_uuid: PropTypes.string,
  ask_uuid: PropTypes.string,
  preview: PropTypes.bool.isRequired,
  appSettings: PropTypes.shape({
    brandingTitle: PropTypes.string,
    brandingLink: PropTypes.string,
  }),
};

export default Experience;
