import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: 'center';
  padding: 0.5rem 1rem;
  box-sizing: border-box;
`;

export const StepBarsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StepBar = styled.div`
  width: 100%;
  height: 4px;
  background: ${(props) =>
    props.filled ? '#fff' : 'rgba(255, 255, 255, 0.4)'};
  border-radius: 4px;
`;
