import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SwitchWithTitle } from '../../../../switch';
import { SubLabel, Hint } from '../../../../globals';
import TilesSelector from '../../../../tilesSelector';
import VideoSettingsPlayButtonFirst from './images/VideoSettingsPlayButtonFirst';
import VideoSettingsPlayButtonNone from './images/VideoSettingsPlayButtonNone';
import VideoSettingsBoth from './images/VideoSettingsBoth';

function VideoSettingsSection({ videoStartingScreen, customizeVideoBlocks }) {
  const [isEnabled, setIsEnabled] = useState(customizeVideoBlocks);
  const [checkedValue, setCheckedValue] = useState(videoStartingScreen);

  const options = [
    {
      name: 'play_button_none',
      value: 'play_button_none',
      label: 'Hide play button',
      testId: 'ExperienceSettings/videoPlayButtonNone',
      IconComponent: VideoSettingsPlayButtonNone,
    },

    {
      name: 'play_button_first',
      value: 'play_button_first',
      label: 'Play button first',
      testId: 'ExperienceSettings/videoPlayButtonFirst',
      IconComponent: VideoSettingsPlayButtonFirst,
    },

    {
      name: 'both',
      value: 'both',
      label: 'Show together',
      testId: 'ExperienceSettings/videoBoth',
      IconComponent: VideoSettingsBoth,
    },
  ];

  return (
    <>
      <SwitchWithTitle
        title="Customize video blocks"
        checked={isEnabled}
        data-testid="ExperienceSettings/customizeVideoBlocks"
        onChange={() => setIsEnabled(!isEnabled)}
      />
      {isEnabled && (
        <>
          <SubLabel>Starting screen</SubLabel>
          <Hint data-testid="VideoSettingsSection/Hint">
            Choose when the response buttons appear. They will either be shown
            after the responder taps to play or shown together with the play
            button. The video will always start muted while playing on loop
            until a responder taps to play.
          </Hint>
          <TilesSelector
            options={options}
            defaultValue={videoStartingScreen}
            onChange={setCheckedValue}
          />
        </>
      )}
      <input
        type="hidden"
        name="experience_settings_form_object[video_starting_screen]"
        value={checkedValue}
      />
      <input
        type="hidden"
        name="experience_settings_form_object[customize_video_blocks]"
        value={isEnabled}
      />
    </>
  );
}

VideoSettingsSection.propTypes = {
  videoStartingScreen: PropTypes.string,
  customizeVideoBlocks: PropTypes.bool,
};

export default VideoSettingsSection;
