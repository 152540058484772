import React from 'react';
import PropTypes from 'prop-types';
import { Fieldset, Label } from '../../../../../globals';
import { EditableReadOnlyMentionsInput } from '../../../../../SlateElements';
import {
  getExperiencesList,
  getBlocksList,
  getCustomMergeFields,
} from '../../../../../api';
import TimeSection from '../TimeSection';
import ScheduleActions from '../ScheduleActions';
import ConditionalActionSection from '../ConditionalActionSection';
import { buildInitialConditions } from '../../utils';

const queryParams = { block_type: 'donation' };

const mentionSteps = [
  {
    type: 'experiences',
    title: 'Experiences',
    referencePrefix: 'experience_',
    loadList: () => getExperiencesList(queryParams),
  },
  {
    type: 'blocks',
    title: 'Blocks',
    referencePrefix: 'block_',
    loadList: (experienceId) => getBlocksList(experienceId, queryParams),
  },
  {
    type: 'merge_fields',
    title: 'Block merge fields',
    referencePrefix: '',
    loadList: () =>
      getCustomMergeFields([
        { title: 'Subscription ID', value: 'metadata.subscription_id' },
      ]),
  },
];

function CancelRecurringActionForm({
  attributes,
  errors,
  serializedErrors,
  experienceId,
}) {
  return (
    <>
      <Fieldset>
        <Label>Subscription id</Label>
        <EditableReadOnlyMentionsInput
          name="event_action[subscription_reference]"
          placeholder="Search payment blocks"
          initialValue={attributes.subscriptionReference}
          experienceId={experienceId}
          steps={mentionSteps}
          error={
            errors.subscription_reference && errors.subscription_reference[0]
          }
          inputProps={{ 'data-testid': 'CancelRecurringActionForm/Input' }}
        />
      </Fieldset>
      <ConditionalActionSection
        initialConditions={buildInitialConditions(attributes.conditions)}
        experienceId={experienceId}
        errors={serializedErrors}
      />
      <ScheduleActions
        attributes={attributes}
        errors={errors}
        withoutRecurring
      />
      <TimeSection specificTime={attributes.specificTime} />
    </>
  );
}

CancelRecurringActionForm.propTypes = {
  experienceId: PropTypes.number,
  errors: PropTypes.object,
  attributes: PropTypes.shape({
    subscriptionReference: PropTypes.string,
  }),
};

export default CancelRecurringActionForm;
