import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

import { useTranslation } from '../../../i18n';
import {
  layoutOptions,
  WIDE,
  generateWidgetCode,
  generateWidgetTriggerCode,
} from './utils';
import TileSelector from '../../tilesSelector';
import Box from '@mui/material/Box';
import Dropdown from '../../dropdown';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

const TYPE_TRIGGER = 'Element trigger';
const TYPE_SELECTOR = 'CSS selector';

const dropdownItems = [
  { title: TYPE_TRIGGER, value: TYPE_TRIGGER },
  { title: TYPE_SELECTOR, value: TYPE_SELECTOR },
];

function LightboxSection({ experienceUrl, widgetUrl, learnMoreUrl }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'LightboxSection',
  });
  const [layout, setLayout] = useState(WIDE);
  const [selectorType, setSelectorType] = useState(TYPE_TRIGGER);
  const [selector, setSelector] = useState(null);
  const [widgetCode, setWidgetCode] = useState('');
  const [triggerCode, setTriggerCode] = useState('');

  useEffect(() => {
    const windowId = nanoid();
    const trigger = nanoid();

    setWidgetCode(
      generateWidgetCode({
        layout,
        experienceUrl,
        widgetUrl,
        selector,
        trigger,
        windowId,
      }),
    );

    setTriggerCode(generateWidgetTriggerCode(trigger));
  }, [layout, selector]);

  const copyCode = (code) => {
    navigator.clipboard.writeText(code);
  };

  const handleLayoutChange = (value) => {
    setLayout(value);
  };

  const handleTypeSelection = (value) => {
    setSelector(null);
    setSelectorType(value);
  };

  return (
    <>
      <div className="popup__header">Options</div>
      <form>
        <div className="widget-form__input_wrapper">
          <label className="form__label">LAYOUT</label>
          <div className="popup__hint">{t('layoutHint')}</div>
          <TileSelector
            options={layoutOptions}
            defaultValue={WIDE}
            onChange={handleLayoutChange}
          />
        </div>
      </form>
      <div className="popup__header">
        <div className="popup__header--left">Code</div>
        <Box sx={{ display: 'inline-block', float: 'right' }}>
          <Dropdown
            actionButton={
              <Box
                sx={{
                  position: 'relative',
                  p: '0.375rem 1.75rem 0.375rem 0.75rem',
                  backgroundColor: 'var(--background-secondary)',
                  border: '1px solid var(--border-color-primary)',
                  borderRadius: '0.25rem',
                  opacity: 0.85,
                  fontWeight: 500,
                  fontSize: '0.75rem',
                  lineHeight: '1rem',
                  color: 'var(--font-color-primary)',
                }}
              >
                {selectorType}
                <KeyboardArrowDownOutlinedIcon
                  sx={{ position: 'absolute', right: '5px', fontSize: 18 }}
                />
              </Box>
            }
            menuItems={dropdownItems}
            onSelection={handleTypeSelection}
            listStyle={{ width: '150px' }}
          />
        </Box>
      </div>
      {selectorType === TYPE_TRIGGER ? (
        <>
          <div className="popup__hint-header">
            1. Copy & paste hyperlink code
          </div>
          <div className="popup__hint">
            If you’d like to make your own element trigger a lightbox, copy the
            code below and replace the <code>&lt;a&gt;</code> object with your
            own element. Make sure the <code>data-ha-widget-trigger</code>{' '}
            attribute and value are applied.
          </div>
          <textarea
            className="popup__textarea"
            style={{ minHeight: '84px' }}
            rows="3"
            value={triggerCode}
            readOnly
          />
          <button
            className="popup__copy-button popup__copy-button--static button--mobile-hero"
            onClick={() => copyCode(triggerCode)}
            data-action={'widget-tabs#showCopyNotice'}
          >
            Copy Code
          </button>
        </>
      ) : (
        <>
          <div className="popup__hint-header">1. CSS selector</div>
          <div className="popup__hint">
            Enter the CSS selector of the element that should trigger the lightbox.
          </div>
          <input
            aria-label="css selector"
            value={selector}
            onChange={(e) => setSelector(e.target.value)}
          />
        </>
      )}
      <div className="popup__hint-header">
        2. Copy & paste code before &lt;/head&gt; tag
      </div>
      <div className="popup__hint">
        Paste this into your global site header. If your website has multiple
        headers, paste this code in the header of every page that you want a
        button to be shown.{' '}
        <a href={learnMoreUrl} target="_blank">
          Learn more
        </a>
      </div>
      <textarea
        className="popup__textarea"
        style={{ minHeight: '280px' }}
        rows="12"
        value={widgetCode}
        readOnly
      />
      <button
        className="popup__copy-button popup__copy-button--static button--mobile-hero"
        onClick={() => copyCode(widgetCode)}
        data-action={'widget-tabs#showCopyNotice'}
      >
        Copy Code
      </button>
    </>
  );
}

LightboxSection.propTypes = {
  experienceUrl: PropTypes.string.isRequired,
  widgetUrl: PropTypes.string.isRequired,
  learnMoreUrl: PropTypes.string,
};

export default LightboxSection;
