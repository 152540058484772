import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../modal';
import clsx from 'clsx';
import { NavBar, NavItem } from './style';
import { getValueFromStore } from '../../../../sharedStore';
import { redirectTo } from '../../../../utils';

const handleSignOutClick = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const isSignOutLink = (element) => {
  if (!element) {
    return false;
  }
  return element.classList.contains('signout');
};

const formClasses = [
  'ask-edit__form',
  'media-background',
  'preview_page__form-wrapper',
];

function NavigationHeader({
  activeTab: defaultActiveTab,
  linkToSettings,
  linkToBackground,
  linkToPreview,
}) {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const [formChanged, setFormChanged] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [clickedLink, setClickedLink] = useState(null);
  const [clickedLinkElement, setClickedLinkElement] = useState(null);

  const navItems = [
    {
      title: 'Blocks',
      isActive: activeTab === 'blocks',
      link: '#',
      testId: 'NavigationHeader/Blocks',
      tab: 'blocks',
    },
    {
      title: 'Settings',
      isActive: activeTab === 'settings',
      link: linkToSettings,
      testId: 'NavigationHeader/Settings',
      tab: 'settings',
    },
    {
      title: 'Media',
      isActive: activeTab === 'background',
      link: linkToBackground,
      testId: 'NavigationHeader/Background',
      tab: 'background',
    },
    {
      title: 'Preview',
      isActive: activeTab === 'preview',
      link: linkToPreview,
      testId: 'NavigationHeader/Preview',
      tab: 'preview',
    },
  ];

  const hideModal = (e) => {
    e.stopPropagation();
    setModalIsOpen(false);
  };
  const modalButtons = [
    {
      title: 'Cancel',
      onClick: hideModal,
    },
    {
      title: 'Continue',
      onClick: () => {
        if (isSignOutLink(clickedLinkElement)) {
          clickedLinkElement.removeEventListener('click', handleSignOutClick);
          clickedLinkElement.click();
          return;
        } else if (clickedLink === '#') {
          setActiveTab('blocks');
          setModalIsOpen(false);
        }
        redirectTo(clickedLink);
      },
      primary: true,
    },
  ];

  const handleClick = (link, tab) => {
    if (link) {
      if (getValueFromStore('blockFormChanged') || formChanged) {
        const showConfirmationModal = !['duplicate', '#embed_code', '#share'].some(str => link.includes(str));
        setClickedLink(link);
        setModalIsOpen(showConfirmationModal);
        return;
      }

      redirectTo(link);

      if (tab) {
        setActiveTab(tab);
      }
    } else if (tab) {
      setActiveTab(tab);
    }
  };

  const handleSignOutLinkClick = (element) => {
    if (getValueFromStore('blockFormChanged') || formChanged) {
      setClickedLinkElement(element);
      setModalIsOpen(true);
      return;
    }

    element.removeEventListener('click', handleSignOutClick);
    element.click();
  };

  useEffect(() => {
    const handleLinkClick = (e) => {
      e.preventDefault();

      if (isSignOutLink(e.currentTarget)) {
        handleSignOutLinkClick(e.currentTarget);
      } else {
        handleClick(e.currentTarget.href);
      }
    };

    const handleBlockListClick = (e) => {
      const path = e.path || (e.composedPath && e.composedPath());

      if (path) {
        e.preventDefault();
        const anchor = path.find((item) => item.href);

        if (anchor) {
          handleClick(anchor.href);
        }
      }
    };

    const handleFormChange = () => {
      setFormChanged(true);
    };
    const form = document.querySelector('form.ask-edit__form');
    const blockList = document.querySelector('.ask-edit__blocks');
    const desktopBackButton = document.querySelector(
      '.experience-header__link',
    );
    const dropdowmnItemLinks = document.querySelectorAll(
      '.dropdown__item--link',
    );

    if (form) {
      form.addEventListener('change', handleFormChange);
    }

    if (blockList) {
      blockList.addEventListener('click', handleBlockListClick);
    }

    if (desktopBackButton) {
      desktopBackButton.addEventListener('click', handleLinkClick);
    }

    if (dropdowmnItemLinks) {
      dropdowmnItemLinks.forEach((link) => {
        if (isSignOutLink(link)) {
          link.addEventListener('click', handleSignOutClick);
        }
        link.addEventListener('click', handleLinkClick);
      });
    }

    return () => {
      form.removeEventListener('change', handleFormChange);
      blockList.removeEventListener('click', handleBlockListClick);
      desktopBackButton.removeEventListener('click', handleLinkClick);
      dropdowmnItemLinks.forEach((link) => {
        if (isSignOutLink(link)) {
          link.removeEventListener('click', handleSignOutClick);
        }
        link.removeEventListener('click', handleLinkClick);
      });
    };
  }, [formChanged]);

  useEffect(() => {
    const blocksEl = document.querySelector('.ask-edit__blocks');

    formClasses.forEach((className) => {
      const el = document.querySelector(`.${className}`);
      if (el) {
        if (activeTab === 'blocks') {
          blocksEl.classList.add('ask-edit__blocks--active');
          el.classList.add('hide-on-md');
        } else {
          blocksEl.classList.remove('ask-edit__blocks--active');
          el.classList.remove('hide-on-md');
        }
      }
    });
  }, [activeTab]);

  return (
    <>
      <NavBar data-testid="NavigationHeader/NavBar">
        {navItems.map(({ title, isActive, link, tab, testId }) => (
          <NavItem
            key={title}
            className={clsx(isActive && 'active-link')}
            onClick={() => handleClick(link, tab)}
            data-testid={testId}
          >
            {title}
          </NavItem>
        ))}
        {modalIsOpen && (
          <Modal
            isOpen={modalIsOpen}
            title="Unsaved Changes"
            content="You have unsaved changes. Are you sure you'd like to continue?"
            buttons={modalButtons}
            hideModal={hideModal}
          />
        )}
      </NavBar>
    </>
  );
}

NavigationHeader.propTypes = {
  activeTab: PropTypes.string.isRequired,
  linkToSettings: PropTypes.string,
  linkToBackground: PropTypes.string,
  linkToPreview: PropTypes.string,
  linkBack: PropTypes.string,
};

export default NavigationHeader;
