import React from 'react';
import Box from '@mui/material/Box';

export default function TitleWrapper({ staticTitle, sx, children, ...rest }) {
  return (
    <Box
      data-testid="Preview/Title"
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        color: 'var(--white)',
        height: '100%',
        zIndex: 1,
        '& #TitleMainWrapper': {
          display: staticTitle ? 'none' : 'block',
        },
        '& h1, h2, h3, h4, p': {
          margin: 0,
          fontWeight: 500,
          '& span': {
            pt: '2px',
            pb: '4px',
          },
          '& span.withLineHeight': {
            display: 'inline-block',
          },
        },
        '& ul, ol': {
          fontWeight: 500,
          listStyle: 'revert',
          margin: 'revert',
          padding: 'revert',
          paddingInlineStart: '1.5em',
        },
        ...(sx && sx),
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
