import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../checkbox';

function Disclaimer({ label }) {
  return (
    <Checkbox
      label={label}
      data-testid="Preview/Disclaimer"
      checkedByDefault={false}
    />
  );
}

Disclaimer.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Disclaimer;
