import styled from 'styled-components';

export const Form = styled.form`
  position: relative;
  background-color: var(--background-secondary);
  border: 1px solid var(--border-color-primary);
  padding: 5px 15px;
  border-radius: 8px;
  margin-top: -5px;
  text-align: left;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
`;

export const CancelButtonWrapper = styled.div`
  width: 30%;
  margin-right: 10px;
`;
