const RESTRICTED_CHARS = /\s|:|\/|\?|#|\[|\]|@|!|\$|&|\'|\(|\)|\*|\+|\,|\;\=/g;
function replaceRestrictedChars(name) {
  return name.replace(RESTRICTED_CHARS, '');
}

function getActiveFields(fields) {
  return fields.filter(
    (field) => field.id === undefined || field.deleted === false,
  );
}

export function initialState(initialFields) {
  const fields = initialFields.map((field) => ({
    errors: null,
    ...field,
    deleted: false,
  }));

  return {
    fields: fields.length === 0 ? [{ name: '' }] : fields,
    withDelete: initialFields.length > 1,
  };
}

export function reducer(state, action) {
  let index, nextFields;

  switch (action.type) {
    case 'addNewItem':
      return {
        fields: [...state.fields, { name: '' }],
        withDelete: true,
      };
    case 'changeItemName':
      index = action.payload.index;
      nextFields = [
        ...state.fields.slice(0, index),
        {
          ...state.fields[index],
          name: replaceRestrictedChars(action.payload.name),
          errors: null,
        },
        ...state.fields.slice(index + 1),
      ];

      return {
        ...state,
        fields: nextFields,
      };
    case 'deleteItem':
      index = action.payload.index;

      if (state.fields[index].id) {
        nextFields = [
          ...state.fields.slice(0, index),
          {
            ...state.fields[index],
            deleted: true,
          },
          ...state.fields.slice(index + 1),
        ];
      } else {
        nextFields = [
          ...state.fields.slice(0, index),
          ...state.fields.slice(index + 1),
        ];
      }
      return {
        ...state,
        fields: nextFields,
        withDelete: getActiveFields(nextFields).length !== 1,
      };
    default:
      throw new Error();
  }
}
