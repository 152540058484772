import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from './components/ToggleButton';
import DropdownMenu from './components/DropdownMenu';
import { Wrapper } from 'react-aria-menubutton';

function Dropdown(props) {
  const {
    wrapperClassName,
    onSelection,
    onMenuToggle,
    actionButton,
    position,
    listStyle,
    menuItems,
  } = props;
  return (
    <Wrapper
      className={`AriaMenuButton ${
        wrapperClassName || 'block-transition-pill'
      }`}
      onSelection={onSelection}
      onMenuToggle={onMenuToggle}
    >
      <ToggleButton actionButton={actionButton} />
      <DropdownMenu
        position={position}
        listStyle={listStyle}
        menuItems={menuItems}
      />
    </Wrapper>
  );
}

Dropdown.propTypes = {
  actionButton: PropTypes.element.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  onSelection: PropTypes.func,
  onMenuToggle: PropTypes.func,
  wrapperClassName: PropTypes.string,
};

export default Dropdown;
