import ThinIcon from './images/thin.svg';
import WideIcon from './images/wide.svg';

export const WIDE = 'wide';
export const THIN = 'thin';

export const layoutOptions = [
  {
    icon: '',
    labelBackground: `var(--background-secondary) url(${ThinIcon}) center/auto no-repeat`,
    label: 'Fixed',
    name: 'layout',
    testId: 'LightboxSection/ThinLayout',
    value: THIN,
  },
  {
    icon: '',
    labelBackground: `var(--background-secondary) url(${WideIcon}) center/auto no-repeat`,
    label: 'Responsive',
    name: 'layout',
    testId: 'LightboxSection/WideLayout',
    value: WIDE,
  },
];

export const generateWidgetTriggerCode = (trigger) =>
  `<a href="javascript:void(0)" data-ha-widget-trigger="${trigger}">Learn More</a>`;

const setTriggerAttribute = (selector, trigger) => selector ? `"selector": "${selector}"` : `"trigger": "${trigger}"`;

export const generateWidgetCode = ({
  layout,
  experienceUrl,
  widgetUrl,
  selector,
  trigger,
  windowId,
}) =>
  `<!-- Widget Code -->
<script src="${widgetUrl}" defer></script>

<!-- Widget Code -->
<script class="ha-widget-config" type="application/json">
  {
    "url": "${experienceUrl}",
    ${setTriggerAttribute(selector, trigger)},
    "layout": "${layout}",
    "windowId": "${windowId}"
  }
</script>`;
