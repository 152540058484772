import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SubLabel, HLine } from '../../../../../../../../globals';

import { getOutboundLists } from '../../../../../../../../api';
import OutboundListItem from './components/OutboundListItem';

function OutboundList({ experienceUuid, isNumbersSent }) {
  const [amount, changeAmount] = useState(0);
  const [lists, setLists] = useState([]);

  const prepareList = ({ id, attributes}) => {
    changeAmount((amount) => amount + attributes.numbersCount);

    return {id, ...attributes};
  };

  useEffect(() => {
    if (isNumbersSent) {
      getOutboundLists(experienceUuid).then(({ data }) => {
        changeAmount(0);

        setLists(data.map(prepareList));
      });
    };
  }, [isNumbersSent]);


  return (
    <>
      <SubLabel>Outbound Lists ({amount})</SubLabel>
      <HLine />
      {lists.map((list, key) => (
        <OutboundListItem {...{ list, key }} />
      ))}
    </>
  );
}

OutboundList.propTypes = {
  experienceUuid: PropTypes.string.isRequired,
  isNumbersSent: PropTypes.bool.isRequired,
};

export default OutboundList;
