import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SwitchWithTitle } from '../../../../../switch';
import { Label } from '../../../../../globals';
import TimeSelector from '../../../../../inputs/TimeSelector';
import { Hint, HLine } from '../../../../../globals';

function TimeSection({ specificTime }) {
  const [isChecked, setIsChecked] = useState(!!specificTime);

  const handleSwitchCheck = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <div
        style={{ marginBottom: '2rem' }}
        data-testid="ActionSection/SpecificTime"
      >
        <SwitchWithTitle
          checked={isChecked}
          onChange={handleSwitchCheck}
          title="Run at a specific time"
          data-testid="TimeSection/Switcher"
        >
          <Hint>
            Select a time that you want this action to
            run at. If it has already passed that time today, it will run
            tomorrow.
          </Hint>
          <Label>Time</Label>
          <TimeSelector
            name="event_action[specific_time]"
            value={specificTime || new Date()}
          />
        </SwitchWithTitle>
        {!isChecked && (
          <input
            type="hidden"
            name="event_action[specific_time]"
            defaultValue={null}
          />
        )}
      </div>
      <HLine color="#333" style={{ marginBottom: '2rem' }} />
    </>
  );
}

TimeSection.propTypes = {
  specificTime: PropTypes.string,
};

export default TimeSection;
