import React from 'react';
import Box from '@mui/material/Box';
import TitleWrapper from './TitleWrapper';

export default function PreviewTitle({
  isVisible,
  titleHtml,
  titleStyle,
  staticTitle,
  handlePreviewLinkClick,
}) {
  if (!isVisible) {
    return null;
  }

  return (
    <TitleWrapper staticTitle={staticTitle}>
      <Box id="TitleMainWrapper">
        <Box sx={{ opacity: 0 }} id="TitleParent">
          <Box
            sx={{
              borderWidth: 0,
              borderStyle: 'solid',
              '&#TitleChild': {
                '& h1, & h2, & h3, & h4': {
                  lineHeight: '1.4em',
                },
                '& small': {
                  display: 'block',
                },
                '& li a span': {
                  color: 'var(--link-color)',
                },
                '&& span.preview_reference': {
                  padding: '2px 15px',
                  background: 'rgba(255, 255, 255, 0.16)',
                  borderRadius: '52px',
                  outline: 'none',
                  cursor: 'text',

                  '&:focus': {
                    borderRadius: 0,
                    padding: '0px 5px 1px',
                  },
                },
                ...titleStyle,
              },
            }}
            id="TitleChild"
          >
            <Box
              onClick={handlePreviewLinkClick}
              dangerouslySetInnerHTML={{ __html: titleHtml }}
            />
          </Box>
        </Box>
      </Box>
    </TitleWrapper>
  );
}
