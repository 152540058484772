import React from 'react';

export default function VideoSettingsPlayButtonNone() {
  return (
    <svg
      width="108"
      height="108"
      viewBox="0 0 108 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="28"
        y="18"
        width="52"
        height="72"
        rx="2.78571"
        fill="#333333"
        stroke="#BDBDBD"
        strokeWidth="0.928571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="34" y="78" width="40" height="6" rx="1.09091" fill="#0078F5" />
      <rect x="34" y="70" width="12" height="6" rx="1.09091" fill="white" />
      <rect
        x="62.0682"
        y="70.0682"
        width="11.8636"
        height="5.86364"
        rx="1.02273"
        fill="#727278"
        stroke="#BDBDBD"
        strokeWidth="0.136364"
      />
      <rect
        x="48.0682"
        y="70.0682"
        width="11.8636"
        height="5.86364"
        rx="1.02273"
        fill="#727278"
        stroke="#BDBDBD"
        strokeWidth="0.136364"
      />
    </svg>
  );
}
