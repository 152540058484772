import React from 'react';
import { SnackbarProvider } from 'notistack';

export default function withSnackbarProvider(Component) {
  return (props) => (
    <SnackbarProvider dense autoHideDuration={3000}>
      <Component {...props} />
    </SnackbarProvider>
  );
}
