import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { PrimaryButton } from '../../../../../../../Button';

function EmailBuilderNew({ showEmailBuilder }) {
  const handleBuildEmailClick = (event) => {
    event.preventDefault();
    showEmailBuilder();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 110,
        marginTop: '22px',
        border: '1px dashed var(--border-color-secondary)',
        borderRadius: '8px',
      }}
    >
      <PrimaryButton style={{ width: 200 }} onClick={handleBuildEmailClick}>
        Build email
      </PrimaryButton>
    </Box>
  );
}

EmailBuilderNew.propTypes = {
  showEmailBuilder: PropTypes.func,
};

export default EmailBuilderNew;
