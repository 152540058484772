import React from 'react';

export default function HiddenInputs({ conditions, isChecked }) {
  return (
    <>
      {conditions.map((condition, index) => {
        if (isChecked) {
          return (
            <React.Fragment key={condition.id || condition.nanoid}>
              <input
                type="hidden"
                defaultValue={condition.id}
                name={`event_action[conditions_attributes][${index}][id]`}
              />
              <input
                type="hidden"
                defaultValue={condition.first_value}
                name={`event_action[conditions_attributes][${index}][first_value]`}
              />
              <input
                type="hidden"
                defaultValue={condition.second_value}
                name={`event_action[conditions_attributes][${index}][second_value]`}
              />
              <input
                type="hidden"
                defaultValue={condition.operator}
                name={`event_action[conditions_attributes][${index}][operator]`}
              />
              <input
                type="hidden"
                defaultValue={condition.data_type}
                name={`event_action[conditions_attributes][${index}][data_type]`}
              />
              <input
                type="hidden"
                defaultValue={condition._destroy}
                name={`event_action[conditions_attributes][${index}][_destroy]`}
              />
            </React.Fragment>
          );
        } else if (condition.id) {
          return (
            <React.Fragment key={condition.id}>
              <input
                type="hidden"
                defaultValue={condition.id}
                name={`event_action[conditions_attributes][${index}][id]`}
              />
              <input
                type="hidden"
                defaultValue={condition._destroy}
                name={`event_action[conditions_attributes][${index}][_destroy]`}
              />
            </React.Fragment>
          );
        }
      })}
    </>
  );
}
