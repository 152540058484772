import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SubLabel, Hint } from '../../../../../../../../globals';
import { PrimaryButton } from '../../../../../../../../Button';
import Modal from '../../../../../../../../modal';
import toast from '../../../../../../../../toast';
import { createOutboundList } from '../../../../../../../../api';

import { Textarea, ErrorMessage, InputWrapper } from './style';
import { validPhoneNumbers, modalContent } from './utils';

import manualOkMessage from './ManualChatbotApproveOkMessage';
import useWorkspace from '../../../../../../../../hooks/useWorkspace';

const MAXIMUM_NUMBERS_COUNT = 10000;
const OK_TIMEOUT = 6000;

function OutboundSends({
  experienceUuid,
  chatbotAutoApprove,
  setIsNumbersSent,
  blacklistNumbers,
}) {
  const [inputValue, setInputValue] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [limitNumbersError, setLimitNumbersError] = useState(false);
  const [blacklistedNumbersCount, setBlacklistedNumbersCount] = useState(0);
  const workspaceId = useWorkspace();

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const successMessage = () => {
    if (chatbotAutoApprove) {
      return 'Your texts will be sent in a jiffy!';
    }

    return new manualOkMessage().show(workspaceId);
  };

  const submitButtonText = () => {
    if (chatbotAutoApprove) {
      return 'Send texts';
    }

    return 'Queue Texts';
  };

  const handleSendError = (e) => {
    const perror = JSON.parse(e.message).errors;

    setIsModalOpen(false);
    if (perror && perror.asker) {
      setError(perror.asker[0]);
    } else {
      toast('Sorry, something went wrong.', 'error');
    }
  };

  const handleSend = (e) => {
    setIsNumbersSent(false);
    // Disable Send button
    e.target.disabled = true;

    createOutboundList(experienceUuid, phoneNumbers)
      .then(() => {
        setIsModalOpen(false);
        setInputValue('');
        setPhoneNumbers([]);
        toast(successMessage(), 'success', OK_TIMEOUT);
      })
      .catch(handleSendError)
      .finally(() => {
        setIsNumbersSent(true);
      });
  };
  const modalButtons = [
    { title: 'Cancel', onClick: handleCancel },
    { title: 'Confirm', onClick: handleSend, primary: true },
  ];

  const handleSendTexts = () => {
    const phoneNumbers = validPhoneNumbers(inputValue);
    const filteredPhoneNumbers = phoneNumbers.filter(
      (number) => !blacklistNumbers.includes(number),
    );

    if (filteredPhoneNumbers.length > MAXIMUM_NUMBERS_COUNT) {
      setLimitNumbersError(true);
    } else if (filteredPhoneNumbers.length > 0) {
      setPhoneNumbers(filteredPhoneNumbers);
      setBlacklistedNumbersCount(
        phoneNumbers.length - filteredPhoneNumbers.length,
      );
      setIsModalOpen(true);
    } else {
      setError('must include one valid phone number');
    }
  };

  const handleTextChange = ({ target: { value } }) => {
    setError(null);
    setLimitNumbersError(false);
    setInputValue(value);
  };


  return (
    <>
      <SubLabel>Outbound sends</SubLabel>
      <Hint>
        Enter phone number(s) separated by a new line or comma. You must have
        receieved prior permission before sending SMS messages.
      </Hint>
      <InputWrapper data-testid="ChatbotSettings/phoneInput">
        <Textarea
          placeholder="Paste a list of numbers..."
          value={inputValue}
          onChange={handleTextChange}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {limitNumbersError && (
          <ErrorMessage>{`you can submit ${MAXIMUM_NUMBERS_COUNT} numbers at a time`}</ErrorMessage>
        )}
        <PrimaryButton onClick={handleSendTexts}>
          {submitButtonText()}
        </PrimaryButton>
      </InputWrapper>
      <Modal
        title="Confirm Send"
        isOpen={isModalOpen}
        content={modalContent(phoneNumbers.length, blacklistedNumbersCount)}
        buttons={modalButtons}
      />
    </>
  );
}

OutboundSends.propTypes = {
  experienceUuid: PropTypes.string.isRequired,
  chatbotAutoApprove: PropTypes.bool.isRequired,
  setIsNumbersSent: PropTypes.func.isRequired,
  blacklistNumbers: PropTypes.arrayOf(PropTypes.string),
};

export default OutboundSends;
