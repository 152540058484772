import React from 'react';
import PropTypes from 'prop-types';
import { FlexRow } from '../../../../../globals';
import { Clock, RefreshCw } from 'react-feather';
import Tooltip from '../../../../../tooltip';
import { toHumanReadableTime } from '../../../../../../utils';

function RecurringIcons({ attributes }) {
  const { specificTime, frequency, interval } = attributes;

  const frequencyTooltipTitle = (frequency) => {
    const titles = {
      daily: 'Repeats every day',
      weekly: 'Repeats every week',
      monthly: 'Repeats every month',
      yearly: 'Repeats every year',
    };

    return titles[frequency];
  };

  return (
    <FlexRow>
      {specificTime && (
        <Tooltip
          data-testid="RecurringIcons/TimeTooltip"
          disableFocusListener
          disableTouchListener
          title={`Runs at ${toHumanReadableTime(specificTime)}`}
          placement="top"
          width={150}
        >
          <FlexRow margin="0 0 0 1rem">
            <Clock data-testid="RecurringIcons/Time" size={14} />
          </FlexRow>
        </Tooltip>
      )}
      {frequency && interval && (
        <Tooltip
          data-testid="RecurringIcons/RecurringTooltip"
          disableFocusListener
          disableTouchListener
          title={frequencyTooltipTitle(frequency)}
          placement="top"
        >
          <FlexRow margin="0 0 0 1rem">
            <RefreshCw data-testid="RecurringIcons/Recurring" size={14} />
          </FlexRow>
        </Tooltip>
      )}
    </FlexRow>
  );
}

RecurringIcons.propTypes = {
  attributes: PropTypes.shape({
    specificTime: PropTypes.string,
    frequency: PropTypes.string,
    interval: PropTypes.number,
  }),
};

export default RecurringIcons;
