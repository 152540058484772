import React from 'react';
import Box from '@mui/material/Box';
import ConditionsSelect from '../../../ConditionsSelect';
import { getSelectDefaultValue } from '../../../../TransitionButton/utils';
import {
  REDIRECT_TRANSITION,
  GO_TO_TRANSITION,
} from '../../../../../../constants';
import { DropdownMenu } from '../../../../../DropdownMenu';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MentionTitle from '../../../../../MentionTitle';

export default function MultipleChoiceCondition({
  transition,
  optionName,
  selectOptions,
  experienceUrlMentionSteps,
  blockUrlMentionSteps,
  experienceId,
  setTransitions,
  onKeyDown,
  ruleIndex,
  errors,
}) {
  if (transition._destroy) {
    return <div />;
  }

  const defaultValue = getSelectDefaultValue(transition, selectOptions);

  const optionTransitionType = transition.transition_type;
  const isRedirectOptionSelected = optionTransitionType === REDIRECT_TRANSITION;
  const isGoToOptionSelected = optionTransitionType === GO_TO_TRANSITION;

  const handleSelectChange = (option) => {
    setTransitions((prev) =>
      prev.map((t, i) => {
        if (i === ruleIndex) {
          return {
            ...prev[i],
            transition_type: option.value,
            redirect_url: '',
            to_block_id: option.data?.to_block_id,
          };
        } else {
          return t;
        }
      }),
    );
  };

  const handleSelectedConditionChange = (value) => {
    setTransitions((prev) =>
      prev.map((t, i) => {
        if (i === ruleIndex) {
          return {
            ...prev[i],
            redirect_url: value,
          };
        } else {
          return t;
        }
      }),
    );
  };

  const handleDelete = () => {
    if (transition.id) {
      setTransitions((prev) =>
        prev.map((t, i) => {
          if (i === ruleIndex) {
            return {
              id: t.id,
              _destroy: true,
            };
          } else {
            return t;
          }
        }),
      );
    } else {
      setTransitions((prev) => prev.filter((_t, i) => i !== ruleIndex));
    }
  };

  return (
    <Box aria-label="transition rule" sx={{ display: 'flex' }}>
      <Box sx={{ width: 'calc(100% - 34px)', mb: 4 }}>
        <ConditionsSelect
          selectProps={{
            options: selectOptions,
            defaultValue,
            onChange: handleSelectChange,
            name: `variant_${transition.transitionable_id}`,
            id: `field_${transition.transitionable_id}`,
          }}
          conditionProps={{
            name: `redirect_url_${transition.transitionable_id}`,
            redirectMentionSteps: experienceUrlMentionSteps,
            goToMentionSteps: blockUrlMentionSteps,
            experienceId,
            onValueChange: handleSelectedConditionChange,
            onKeyDown,
            initialValue: transition.redirect_url || '',
            autoFocus: !transition.redirect_url,
            error: errors[ruleIndex]?.redirect_url,
          }}
          isRedirectOptionSelected={isRedirectOptionSelected}
          isGoToOptionSelected={isGoToOptionSelected}
        />
        <label htmlFor={`${transition.transitionable_id}_field`} className="form__label">
          <MentionTitle title={`IF "${optionName}"`} />
        </label>
      </Box>
      <DropdownMenu
        menuItems={[
          {
            title: 'Delete rule',
            value: 'delete',
            icon: <DeleteOutlineIcon />,
          },
        ]}
        triggerButton={<MoreHorizIcon />}
        onSelection={handleDelete}
        disablePortal
      />
    </Box>
  );
}
