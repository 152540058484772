import styled from 'styled-components';
import React, { useState } from 'react';
import Experience from './components/Experience';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const MobileContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 375px;
  max-height: 600px;
`;

const DesktopContainer = styled(MobileContainer)`
  width: 961px;
  height: 700px;
`;

const themeMobile = createTheme({
  breakpoints: {
    // values: {
    //   xs: 0,
    //   sm: 600,
    //   md: 961,
    //   lg: 1024,
    //   xl: 1920,
    // },
    values: {
      xs: 0,
      sm: 4096,
      // md: 961,
      // lg: 1024,
      // xl: 1920,
    },
  },
});

const themeDesktop = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1024,
      xl: 1920,
    },
  },
});

function PreviewContainer({ isMobile, children }) {
  return (
    <div data-testid="Preview/Container" style={{ height: '100%' }}>
      {isMobile ? (
        <MobileContainer>{children}</MobileContainer>
      ) : (
        <DesktopContainer>{children}</DesktopContainer>
      )}
    </div>
  );
}

function Preview({ workspace_id, experience_uuid, ask_uuid, appSettings }) {
  const [currentTheme, setCurrentTheme] = useState('mobile');

  const isMobile = () => {
    return currentTheme === 'mobile';
  };

  const theme = isMobile() ? themeMobile : themeDesktop;

  return (
    <ThemeProvider theme={theme}>
      {false && (
        <Button
          style={{
            position: 'fixed',
            top: '100px',
            left: '600px',
            width: '200px',
          }}
          variant="outlined"
          onClick={() => {
            isMobile() ? setCurrentTheme('desktop') : setCurrentTheme('mobile');
          }}
        >
          {isMobile() ? 'Mobile Preview' : 'Desktop Preview'}
        </Button>
      )}
      <PreviewContainer isMobile={isMobile()}>
        <Experience
          workspace_id={workspace_id}
          experience_uuid={experience_uuid}
          ask_uuid={ask_uuid}
          preview={true}
          appSettings={appSettings}
        />
      </PreviewContainer>
    </ThemeProvider>
  );
}

export default Preview;
