import React from 'react';

const EventActionContext = React.createContext({});

EventActionContext.displayName = 'Event action context';

export function EventActionProvider({
  children,
  emailBuilder,
  experienceId,
}) {
  return (
    <EventActionContext.Provider value={{ emailBuilder, experienceId }}>
      {children}
    </EventActionContext.Provider>
  );
}

export default EventActionContext;
