import React from 'react';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
import { Container, StepBarsContainer, StepBar } from './style';

const calculateStepsCount = (blockType) => {
  switch (blockType) {
    case 'free_response':
      return 2;

    case 'donation':
      // More parameters for calculation can be added later
      return 3;

    // For all other block types
    default:
      return 0;
  }
};

const renderSteps = (stepsCount) => {
  if (stepsCount === 0) {
    return null;
  }

  const steps = [
    <StepBar key={_uniqueId()} filled data-testid="ProgressBar/Step" />,
  ];
  if (stepsCount > 1) {
    for (let i = 1; i < stepsCount; i++) {
      steps.push(<StepBar key={_uniqueId()} data-testid="ProgressBar/Step" />);
    }
  }

  return steps;
};

function ProgressBar({ blockType }) {
  const stepsCount = calculateStepsCount(blockType);

  return (
    <Container data-testid="Preview/ProgressBarContainer">
      <StepBarsContainer>{renderSteps(stepsCount)}</StepBarsContainer>
    </Container>
  );
}

ProgressBar.propTypes = {
  blockType: PropTypes.string.isRequired,
};

export default ProgressBar;
