import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.flexColumn ? 'column' : 'row')};
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.fontFamily};
  padding-top: 2rem;
  background-color: #000;
`;

export const BottomWrapper = styled.div`
  position: relative;
  z-index: 2;

  & div[aria-label="navigation buttons section"] > div {
    padding: 0; 
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  padding: 0 1rem 1px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.withStaticTitle ? 'flex-start' : 'flex-end'};
  height: ${(props) => (props.withStaticTitle ? '100%' : 'auto')};
  overflow-y: ${(props) => (props.scrollable ? 'auto' : 'visible')};
`;

export const ScrollableContentWrapper = styled.div`
  position: relative;
  padding: 0 1rem 1px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  ${({ contentVerticalAlignment }) => {
  if (contentVerticalAlignment === 'start') {
    return `
    & > div:last-child {
      margin-top: auto;
    }
`;
  }
  if (contentVerticalAlignment === 'center') {
    return `
    & > div:first-child {
      margin-top: auto;
    }
    & > div:last-child {
      margin-top: auto;
    }
`;
  }
  if (contentVerticalAlignment === 'end') {
    return `
    & > div:first-child {
      margin-top: auto;
    }
`;
  }
}}
`;

export const ProgressBarContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
`;

export const BrandingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 18px 16px 8px;
  z-index: 2;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );

  & .branding__logo {
    @media (min-width: 375px) {
      width: 32px;
      height: 32px;
    }

    @media (min-width: 768px) {
      width: 32px;
      height: 32px;
    }

    @media (min-width: 1024px) {
      width: 32px;
      height: 32px;
    }
  }

  & .branding__name {
    @media (min-width: 375px) {
      font-size: 12px;
    }

    @media (min-width: 768px) {
      font-size: 12px;
    }

    @media (min-width: 1024px) {
      font-size: 12px;
    }
  }
`;
