import React from 'react';
import Select from '../Select';
import SelectedCondition from './components/SelectedCondition';

function ConditionsSelect({
  selectProps,
  conditionProps,
  isRedirectOptionSelected,
  isGoToOptionSelected,
}) {
  return (
    <>
      <Select
        {...selectProps}
        flattenBorder={isRedirectOptionSelected || isGoToOptionSelected}
      />
      <SelectedCondition
        {...conditionProps}
        isRedirect={isRedirectOptionSelected}
        isGoTo={isGoToOptionSelected}
      />
    </>
  );
}

export default ConditionsSelect;
