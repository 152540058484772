import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { Type, Upload, Video } from 'react-feather';
import { ButtonsContainer } from '../../style';
import ResponseButton from '../../../shared/ResponseButton';
import ResponseTextField from '../../../shared/ResponseTextField';
import BlockLabel from '../../../shared/BlockLabel';
import Box from '@mui/material/Box';
import CtaButton from '../../../shared/CtaButton';
import StaticTitle from '../StaticTitle';

const buttonProps = {
  video: {
    label: 'Video',
    icon: <Video />,
  },
  upload: {
    label: 'Upload',
    icon: <Upload />,
  },
  text: {
    label: 'Text',
    icon: <Type />,
  },
};

function UgcButtons({
  ugcTypes,
  isTextOnly,
  helperTextIsHidden,
  hasMedia,
  titleHtml,
  titleStyle,
  handlePreviewLinkClick,
  ctaButtonContent,
  ctaButtonStyle,
  backgroundColorOverlay,
  askLabel,
  contentLayout,
}) {
  if (isTextOnly) {
    return (
      <>
        {!hasMedia && (
          <>
            {contentLayout === 'autofit' && (
              <StaticTitle
                titleHtml={titleHtml}
                titleStyle={titleStyle}
                handlePreviewLinkClick={handlePreviewLinkClick}
                isUgcText
              />
            )}
            <ResponseTextField
              placeholder="Enter your response..."
              backgroundColorOverlay={backgroundColorOverlay}
              errors={{}}
              disabled
            />
          </>
        )}
        {hasMedia ? (
          <a className="fake-input-as-button">Enter your response...</a>
        ) : (
          <Box sx={{ mt: 'auto' }}>
            <CtaButton
              ctaButtonStyle={ctaButtonStyle}
              ctaButtonContent={ctaButtonContent}
              isPreview
              noMargin
            />
          </Box>
        )}
      </>
    );
  }

  return (
    <div data-testid="Preview/UgcResponseButtonsWrapper">
      {!helperTextIsHidden && <BlockLabel askLabel={askLabel} isPreview />}
      <ButtonsContainer>
        {ugcTypes.map((type) => (
          <ResponseButton
            key={type}
            text={buttonProps[type].label}
            Icon={() => buttonProps[type].icon}
            data-testid={`UgcButtons/${type}-button`}
          />
        ))}
      </ButtonsContainer>
    </div>
  );
}

UgcButtons.propTypes = {
  ugcTypes: PropTypes.arrayOf(PropTypes.string),
  isTextOnly: PropTypes.bool,
  helperTextIsHidden: PropTypes.bool,
  hasMedia: PropTypes.bool,
  titleHtml: PropTypes.string.isRequired,
  backgroundColorOverlay: PropTypes.string.isRequired,
};

UgcButtons.defaultProps = {
  backgroundColorOverlay: 'rgba(0, 0, 0, 0.2)',
};

export default UgcButtons;
