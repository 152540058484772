import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { createExperiencesFromTemplate } from '../../api';
import { useTranslation } from '../../../i18n';
import { ActionButton } from './style';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Modal from '@mui/material/Modal';

const styles = {
  listItem: {
    paddingTop: '14px',
    paddingBottom: '14px',
    '& .MuiListItemText-primary': {
      fontFamily: 'inherit',
      color: '#000',
      fontWeight: 500,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  subheader: {
    minHeight: 22,
    color: '#bdbdbd',
    backgroundColor: '#f9f9f9',
    fontSize: 11,
    fontWeight: 500,
    letterSpacing: 0.8,
    lineHeight: 1.2,
    textTransform: 'uppercase',
    marginBottom: 0,
    paddingTop: '9px',
    paddingBottom: '9px',
  },
  list: {
    width: '100%',
    maxWidth: 420,
    maxHeight: 494,
    backgroundColor: 'var(--white)',
    overflow: 'auto',
    paddingTop: 0,
    border: '0.5px solid #E0E0E0',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
  },
};

const DROPDOWN_VALUE_NEW = 'new';

const NewExperienceDropdown = ({ templates, workspaceId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'NewExperienceDropdown',
  });
  const [sendingRequest, setSendingRequest] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleItemSelect = (value) => {
    if (value === DROPDOWN_VALUE_NEW) {
      window.location.href = `/${workspaceId}/experiences/new`;
    } else {
      setSendingRequest(true);

      createExperiencesFromTemplate(value)
        .then((response) => {
          if (response.status === 200) {
            window.location.reload();
          }
        })
        .finally(() => setSendingRequest(false));
    }
  };

  return (
    <>
      <ActionButton
        data-testid="NewExperienceDropdown/ActionButton"
        className="button--mobile-hero"
        onClick={handleOpen}
        disabled={sendingRequest}
      >
        {t('buttonText')}
      </ActionButton>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          margin: '1rem',
        }}
      >
        <List sx={styles.list} data-testid="NewExperienceDropdown/List">
          <ListSubheader sx={styles.subheader}>
            Create something new
          </ListSubheader>
          <ListItem
            sx={styles.listItem}
            data-testid="DropdownMenuItem/new"
            button
            onClick={() => handleItemSelect(DROPDOWN_VALUE_NEW)}
          >
            <ListItemText primary="🆕 Start from scratch" />
          </ListItem>
          {!_isEmpty(templates) && (
            <>
              <ListSubheader sx={styles.subheader}>
                Use a template
              </ListSubheader>
              {templates.map((item) => {
                return (
                  <ListItem
                    key={item.value}
                    sx={styles.listItem}
                    data-testid={`DropdownMenuItem/${item.value}`}
                    button
                    onClick={() => handleItemSelect(item.value)}
                  >
                    <ListItemText primary={item.title} />
                  </ListItem>
                );
              })}
            </>
          )}
        </List>
      </Modal>
    </>
  );
};

NewExperienceDropdown.propTypes = {
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ),
};

export default NewExperienceDropdown;
