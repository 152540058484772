import React from 'react';
import SimpleSelect from '../../../../../../../select';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useBuildMentionsSteps from '../../../../../../../hooks/useBuildMentionsSteps';
import { EditableMentionsInput } from '../../../../../../../SlateElements';
import { SmallDeleteButton } from '../../../../../../../Button';
import _differenceBy from 'lodash/differenceBy';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

export default function MappingsList(
  {
    experienceId,
    currentSetupMappings,
    mappingsSelectOptions,
    errors,
    currentSetup,
    onSelect,
    onDelete,
    onAdd,
    onChange,
  },
) {
  const mentionSteps = useBuildMentionsSteps({
    translationsKeyPrefix: 'MentionsInput',
  });

  const setSelectValue = (options, mappableId, mappableType) => {
    const findValue = (o) => {
      return o.value === mappableId &&
        o.integration_mappable_type === mappableType;
    };
    if (mappableType === 'IntegrationField') {
      return options[0].options.find(findValue);
    } else if (mappableType === 'IntegrationMetadatum') {
      return options[1].options.find(findValue);
    }
  };

  return (
    <>
      <Stack gap={1}>
        {currentSetupMappings.map(
          (
            {
              id,
              reference,
              nanoid,
              integration_mappable_id,
              integration_mappable_type,
              _destroy,
            },
            index,
          ) => {
            const key = integration_mappable_id
              ? `${integration_mappable_id}_${integration_mappable_type}`
              : nanoid;
            if (_destroy) {
              return <Box sx={{ position: 'absolute' }} key={key} />;
            } else {
              return (
                <Box
                  key={key}
                  sx={{
                    display: 'grid',
                    gridTemplateColumns:
                      'minmax(100px, 200px) 20px minmax(150px, 1fr) 20px',
                    alignItems: 'center',
                    gap: '6px',
                  }}
                  aria-label="hubspot mapping"
                >
                  <SimpleSelect
                    options={mappingsSelectOptions}
                    value={setSelectValue(
                      mappingsSelectOptions,
                      integration_mappable_id,
                      integration_mappable_type,
                    )}
                    classNamePrefix="react-select__actions__integration_field"
                    onChange={(obj) => onSelect(obj, index)}
                    noMargin
                  />
                  <ArrowBackIcon
                    sx={{
                      fontSize: 18,
                      color: 'var(--font-color-primary--light)',
                    }}
                  />
                  <EditableMentionsInput
                    placeholder='Press "[" to add a reference'
                    initialValue={reference}
                    experienceId={experienceId}
                    steps={mentionSteps}
                    error={errors && errors.integration_mappings &&
                      errors.integration_mappings[index]?.reference}
                    inputStyles={{ maxWidth: '534px' }}
                    onValueChange={(value) => onChange(value, index)}
                    noTrailingSpace
                    noAutocomplete
                  />
                  <SmallDeleteButton
                    onClick={() =>
                      onDelete(
                        id,
                        integration_mappable_id,
                        integration_mappable_type,
                        index,
                      )}
                    noMargin
                  >
                    –
                  </SmallDeleteButton>
                </Box>
              );
            }
          },
        )}
      </Stack>
      {currentSetup
        ? (
          <Box sx={{ mt: 1 }}>
            <Button
              sx={{
                width: 'auto',
                color: 'var(--primary-color)',
                textTransform: 'none',
                borderColor: 'rgba(255, 66, 0, 0.5)',
                borderRadius: '8px',
                '&:hover': {
                  color: 'var(--primary-color)',
                  borderColor: 'var(--primary-color)',
                  backgroundColor: 'rgba(255, 66, 0, 0.04)',
                },
                '&:disabled:hover': {
                  color: 'rgba(0, 0, 0, 0.26)',
                  borderColor: 'rgba(0, 0, 0, 0.12)',
                  backgroundColor: 'transparent',
                },
              }}
              variant="outlined"
              onClick={onAdd}
              disableRipple
            >
              + Add
            </Button>
          </Box>
        )
        : (
          <Card
            sx={{
              p: 2,
              textAlign: 'center',
              backgroundColor: '#FAFAFA',
              borderColor: '#FAFAFA',
            }}
            variant="outlined"
          >
            <Typography variant="subtitle1">This list is empty</Typography>
            <Typography
              sx={{ color: 'rgba(51, 51, 51, 0.6)' }}
              variant="subtitle2"
            >
              Select a HubSpot form first
            </Typography>
          </Card>
        )}
    </>
  );
}
