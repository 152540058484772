import styled from 'styled-components';
import { Check } from 'react-feather';

export const Text = styled.p`
  color: #333;
  line-height: 1.4;
`;

export const List = styled.ul`
  padding: 12px 9px;
  color: #727278;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const StyledSpan = styled.span`
  color: #b6b6b6;
  padding-left: 6px;
`;

export const StyledCheckIcon = styled(Check)`
  margin-right: 12px;
  color: #0078f5;
`;
