import { useReducer } from 'react';

const SET_UNLAYER_BUILDER_DATA = 'setUnlayerBuilderData';
const SET_UNLAYER_BUILDER_DESIGN = 'setUnlayerBuilderDesign';
const SET_EMPTY_STATE = 'setEmptyState';
const SET_ACTIVE_STATE = 'setCustomEmailActiveState';

const initialBuilderState = {
  active: false,
  id: null,
  body: '',
  error: false,
  unlayerJsonData: {},
};

function emailBuilderReducer(state, action) {
  switch (action.type) {
    case SET_UNLAYER_BUILDER_DATA:
      return {
        ...action.payload,
      };
    case SET_UNLAYER_BUILDER_DESIGN:
      const {
        payload: { unlayerJsonData },
      } = action;

      return {
        ...state,
        error: false,
        active: true,
        unlayerJsonData,
        html: unlayerJsonData.html,
      };
    case SET_EMPTY_STATE:
      return {
        ...initialBuilderState,
      };
    case SET_ACTIVE_STATE:
      return {
        ...state,
        active: action.payload.active,
      };
    default:
      throw new Error('Wrong action type');
  }
}

export default function useEmailBuilder(customEmail, serverError) {
  const [email, dispatch] = useReducer(
    emailBuilderReducer,
    initialBuilderState,
  );

  const handleInitCustomEmail = () => {
    if (customEmail) {
      const { active, id, body, unlayerJsonData } = customEmail;
      const parsedUnlayerJsonData = JSON.parse(unlayerJsonData);
      dispatch({
        type: SET_UNLAYER_BUILDER_DATA,
        payload: {
          id,
          active,
          body,
          unlayerJsonData: parsedUnlayerJsonData,
          error: serverError,
        },
      });
    } else {
      dispatch({ type: SET_EMPTY_STATE });
    }
  };

  const handleChooseTemplate = (selectedTemplate) => {
    if (selectedTemplate) {
      const { id, body, unlayerJsonData } = selectedTemplate;
      const parsedUnlayerJsonData = JSON.parse(unlayerJsonData);
      dispatch({
        type: SET_UNLAYER_BUILDER_DATA,
        payload: {
          id,
          body,
          unlayerJsonData: parsedUnlayerJsonData,
          error: serverError,
        },
      });
    } else {
      dispatch({ type: SET_EMPTY_STATE });
    }
  };

  const handleSaveTemplate = (data) => {
    dispatch({
      type: SET_UNLAYER_BUILDER_DESIGN,
      payload: {
        unlayerJsonData: data,
      },
    });
  };

  const handleDeleteEmail = () => {
    dispatch({
      type: SET_EMPTY_STATE,
    });
  };

  const handleSetEmailActiveState = (active) => {
    dispatch({
      type: SET_ACTIVE_STATE,
      payload: { active },
    });
  };

  return {
    email,
    handleInitCustomEmail,
    handleChooseTemplate,
    handleSaveTemplate,
    handleDeleteEmail,
    handleSetEmailActiveState,
  };
}
