import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../../../inputs/Input';
import { SmallDeleteButton } from '../../../../../../Button';
import { InputWrapper, RightButtonWrapper } from '../../style';

const placeholderMapper = {
  UrlParamsSection: 'Enter parameter',
  KeywordsSection: 'Enter keyword',
};

function InputField({
  index,
  name,
  deleted,
  errors,
  withDelete,
  onChange,
  onDelete,
  onEnter,
  sectionName,
  maxLength,
}) {
  if (deleted) {
    return null;
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      onEnter();
    }
  };

  return (
    <InputWrapper>
      <Input
        noMargin={true}
        data-testid={`${sectionName}/Input`}
        value={name}
        placeholder={placeholderMapper[sectionName]}
        maxLength={maxLength}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        error={errors && errors.name && errors.name[0]}
        autoFocus={true}
      />
      <RightButtonWrapper>
        {withDelete && (
          <SmallDeleteButton
            key={`button-${index}`}
            tabIndex="-1"
            onClick={onDelete}
            data-testid={`${sectionName}/DeleteButton`}
          >
            –
          </SmallDeleteButton>
        )}
      </RightButtonWrapper>
    </InputWrapper>
  );
}

InputField.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  deleted: PropTypes.bool,
  errors: PropTypes.object,
  withDelete: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
};

export default InputField;
