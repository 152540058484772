import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  getExperiencesList,
  getBlocksList,
  getMergeFields,
  getDataFieldsByType,
} from '../../../../../api';
import { useTranslation } from '../../../../../../i18n';
import { Input } from '../../../../../inputs/Input';
import { EditableMentionsInput } from '../../../../../SlateElements';
import { Label, Fieldset, Hint } from '../../../../../globals';
import ExpansionPanel from '../../../../../expansionPanel';
import { getErrorMessage } from '../../../../../../utils';
import TimeSection from '../TimeSection';
import ConditionalActionSection from '../ConditionalActionSection';
import { buildInitialConditions } from '../../utils';
import ScheduleActions from '../ScheduleActions';
import EmailBuilderSection from './components/EmailBuilderSection';

const basicStepsWithEmailMergeField = (t) => [
  {
    type: 'experiences',
    title: t('entityCapitalizedPlural'),
    referencePrefix: 'experience_',
    loadList: getExperiencesList,
  },
  {
    type: 'blocks',
    title: 'Blocks',
    referencePrefix: 'block_',
    loadList: getBlocksList,
  },
  {
    type: 'merge_fields',
    title: 'Block merge fields',
    referencePrefix: '',
    loadList: (blockId) => getDataFieldsByType(blockId, 'email'),
  },
];

const basicStepsWithAllMergeFields = (t) => [
  {
    type: 'experiences',
    title: t('entityCapitalizedPlural'),
    referencePrefix: 'experience_',
    loadList: getExperiencesList,
  },
  {
    type: 'blocks',
    title: 'Blocks',
    referencePrefix: 'block_',
    loadList: getBlocksList,
  },
  {
    type: 'merge_fields',
    title: 'Block merge fields',
    referencePrefix: '',
    loadList: getMergeFields,
  },
];

function EmailActionForm({
  isNew,
  experienceId,
  errors,
  serializedErrors,
  attributes,
}) {
  const {
    emailBody,
    emailSubject,
    senderEmail,
    senderName,
    specificTime,
    customEmail,
    conditions,
  } = attributes;
  const { t } = useTranslation();
  const stepsWithEmailMergeField = useMemo(
    () => basicStepsWithEmailMergeField(t),
    [t],
  );
  const stepsWithAllMergeFields = useMemo(
    () => basicStepsWithAllMergeFields(t),
    [t],
  );
  return (
    <>
      <ExpansionPanel
        title="Action Settings"
        isDefaultOpen={true}
        data-testid="ActionSettings/SettingsSwitcher"
      >
        <Fieldset>
          <Label>Send to</Label>
          <Hint>Add one or more email addresses separated by a comma.</Hint>
          <EditableMentionsInput
            name="event_action[email_tos]"
            placeholder="Enter an email addresses"
            initialValue={attributes.emailTos}
            dataTestId="EmailActionForm/SendInput"
            experienceId={experienceId}
            steps={stepsWithEmailMergeField}
            error={getErrorMessage(errors, 'email_tos')}
            inputStyles={{ maxWidth: '534px' }}
            noTrailingSpace
            noAutocomplete
            withHiddenInput
          />
        </Fieldset>
        <Fieldset>
          <Label>CC</Label>
          <EditableMentionsInput
            name="event_action[email_ccs]"
            placeholder="Enter an email addresses"
            initialValue={attributes.emailCcs}
            dataTestId="EmailActionForm/SendInput"
            experienceId={experienceId}
            steps={stepsWithEmailMergeField}
            error={getErrorMessage(errors, 'email_ccs')}
            inputStyles={{ maxWidth: '534px' }}
            noTrailingSpace
            noAutocomplete
            withHiddenInput
          />
        </Fieldset>
        <Fieldset>
          <Label>BCC</Label>
          <EditableMentionsInput
            name="event_action[email_bccs]"
            placeholder="Enter an email addresses"
            initialValue={attributes.emailBccs}
            dataTestId="EmailActionForm/SendInput"
            experienceId={experienceId}
            steps={stepsWithEmailMergeField}
            error={getErrorMessage(errors, 'email_bccs')}
            inputStyles={{ maxWidth: '534px' }}
            noTrailingSpace
            noAutocomplete
            withHiddenInput
          />
        </Fieldset>
        <Fieldset>
          <Label>Email subject</Label>
          <EditableMentionsInput
            name="event_action[email_subject]"
            placeholder="Write a descriptive subject line here..."
            dataTestId="EmailActionForm/SubjectInput"
            initialValue={emailSubject || ''}
            experienceId={experienceId}
            steps={stepsWithAllMergeFields}
            error={getErrorMessage(errors, 'email_subject')}
            inputStyles={{ maxWidth: '534px' }}
            noAutocomplete
            withHiddenInput
          />
        </Fieldset>
        <EmailBuilderSection
          isNew={isNew}
          customEmail={customEmail}
          emailBody={emailBody}
          experienceId={experienceId}
          mentionSteps={stepsWithAllMergeFields}
          errors={errors}
        />
        <Fieldset>
          <Label>Sender name</Label>
          <EditableMentionsInput
            name="event_action[sender_name]"
            placeholder="Enter a sender name"
            initialValue={senderName}
            experienceId={experienceId}
            steps={stepsWithAllMergeFields}
            error={getErrorMessage(errors, 'sender_name')}
            inputStyles={{ maxWidth: '534px' }}
            noAutocomplete
            withHiddenInput
          />
        </Fieldset>
        <Fieldset>
          <Label>Sender email</Label>
          <EditableMentionsInput
            name="event_action[sender_email]"
            placeholder="Enter a sender email"
            initialValue={senderEmail}
            experienceId={experienceId}
            steps={stepsWithEmailMergeField}
            error={getErrorMessage(errors, 'sender_email')}
            inputStyles={{ maxWidth: '534px' }}
            noTrailingSpace
            noAutocomplete
            withHiddenInput
          />
        </Fieldset>
      </ExpansionPanel>
      <ConditionalActionSection
        initialConditions={buildInitialConditions(conditions)}
        experienceId={experienceId}
        errors={serializedErrors}
      />
      <ScheduleActions attributes={attributes} errors={errors} />
      <TimeSection specificTime={specificTime} />
    </>
  );
}

EmailActionForm.propTypes = {
  experienceId: PropTypes.number,
  errors: PropTypes.object,
  attributes: PropTypes.shape({
    emailBody: PropTypes.string,
    emailSubject: PropTypes.string,
    emailTos: PropTypes.string,
    emailCcs: PropTypes.string,
    emailBccs: PropTypes.string,
    senderEmail: PropTypes.string,
    senderName: PropTypes.string,
    interval: PropTypes.number,
    frequency: PropTypes.string,
    specificTime: PropTypes.string,
  }),
};

export default EmailActionForm;
