import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { MoreHorizontal, Copy, Trash, Edit3 } from 'react-feather';
import Dropdown from '../../../../../../../dropdown';
import { useModal } from '../../../../../../../hooks';
import Modal from '../../../../../../../modal';
import { PrimaryButton } from '../../../../../../../Button';
import { deleteCustomEmail } from '../../../../../../../api';

const menuItems = [
  {
    title: 'Copy HTML',
    value: 'copy',
    icon: <Copy color="#919191" />,
  },
  {
    title: 'Delete',
    value: 'delete',
    icon: <Trash color="#919191" />,
  },
];

function EmailBuilderEdit({ customEmail, showEmailBuilder, deleteEmail }) {
  const { id, body } = customEmail;
  const { isOpen, hideModal, showModal } = useModal();

  const handleDropdownSelect = (value) => {
    switch (value) {
      case 'copy':
        navigator.clipboard.writeText(body);
        break;
      case 'delete':
        showModal();
        break;
    }
  };

  const handleEditClick = () => {
    showEmailBuilder();
  };

  const handleCancelDeleteEmail = (e) => {
    e.stopPropagation();
    hideModal();
  };

  const handleDeleteEmail = () => {
    if (!id) {
      deleteEmail();
    }
    deleteCustomEmail(id).then(() => {
      deleteEmail();
    });
    hideModal();
  };

  const modalButtons = [
    { title: 'Cancel', onClick: handleCancelDeleteEmail },
    { title: 'Delete', onClick: handleDeleteEmail, primary: true },
  ];

  return (
    <>
      <Modal
        isOpen={isOpen}
        title="Confirm"
        content="Are you sure you want to delete this email?"
        buttons={modalButtons}
        hideModal={hideModal}
      />
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginTop: '22px',
          padding: 0,
        }}
      >
        <PrimaryButton
          onClick={handleEditClick}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 0,
            padding: '8px 14px',
            width: 'calc(100% - 58px)',
            borderRadius: '12px',
          }}
        >
          <Edit3 size={20} style={{ marginRight: '8px' }} />
          Edit email template
        </PrimaryButton>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '25px',
            width: '48px',
            height: '48px',
            borderRadius: '100%',
            background: '#E0E0E0',
          }}
        >
          <Dropdown
            menuItems={menuItems}
            actionButton={<MoreHorizontal color="rgba(51, 51, 51, 0.54)" />}
            onSelection={handleDropdownSelect}
          />
        </Box>
      </Box>
    </>
  );
}

EmailBuilderEdit.propTypes = {
  customEmail: PropTypes.shape({}),
  showEmailBuilder: PropTypes.func.isRequired,
};

export default EmailBuilderEdit;
