import _uniq from 'lodash/uniq';
import pluralize from 'pluralize';

const REGEXP = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/gim;

const normalizeNumber = (number) => {
  const normalized = number
    .replace(/(\()|(\))|(\s)|(\.)|(\-)/g, '')
    .replace(/^\+1/, '')
    .replace(/^1/, '');

  return `+1${normalized}`;
};

export const validPhoneNumbers = (numbers) => {
  const selectedNumbers = numbers
    .replace(/\,/g, '\n')
    .split('\n')
    .map((e) => e.replace(/ +/g, ''))
    .filter((e) => e !== '')
    .filter((e) => e.match(REGEXP))
    .map(normalizeNumber)
    .filter((e) => e.length >= 12);

  return _uniq(selectedNumbers);
};

export const modalContent = (numbersCount, blacklistedNumbersCount) => {
  const initialMessage = `We found ${numbersCount} valid phone ${pluralize('number', numbersCount)}!`;

  if (blacklistedNumbersCount) {
    return `<div>${initialMessage}</div><div>${blacklistedNumbersCount} phone ${pluralize('number', blacklistedNumbersCount)} opt out from communication</div>`;
  } else {
    return initialMessage;
  }
};
