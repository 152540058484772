import React, { useState } from 'react';
import { SwitchWithTitle } from '../../../../../switch';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Hint } from '../../../../../globals';
import Condition from '../../../../transitions/ConditionalTransitionForm/components/Condition';
import useBuildMentionsSteps from '../../../../../hooks/useBuildMentionsSteps';
import { NewItemButton } from '../../../../../Button';
import _isEmpty from 'lodash/isEmpty';
import HiddenInputs from './components/HiddenInputs';
import _uniqueId from 'lodash/uniqueId';
import { nanoid } from 'nanoid';

const emptyCondition = [
  {
    first_value: '',
    second_value: '',
    operator: 'equals',
    data_type: 'string',
    nanoid: nanoid(),
  },
];
export default function ConditionalActionSection({
  initialConditions,
  experienceId,
  errors,
}) {
  const [isChecked, setIsChecked] = useState(!_isEmpty(initialConditions));
  const [conditions, setConditions] = useState([
    {
      conditions_attributes: _isEmpty(initialConditions)
        ? emptyCondition
        : initialConditions,
    },
  ]);
  const mentionSteps = useBuildMentionsSteps({
    translationsKeyPrefix: 'ActionCard.TextbotActionForm',
  });

  const handleAddCondition = () => {
    setConditions((prev) => [
      {
        conditions_attributes: [
          ...prev[0].conditions_attributes,
          { ...emptyCondition[0], nanoid: nanoid() },
        ],
      },
    ]);
  };

  const handleSwitchChange = () => {
    setConditions((prev) => [
      {
        conditions_attributes: prev[0].conditions_attributes.map((c) => ({
          ...c,
          _destroy: isChecked,
        })),
      },
    ]);
    setIsChecked(!isChecked);
  };

  return (
    <Box sx={{ mb: 4 }}>
      <SwitchWithTitle
        checked={isChecked}
        onChange={handleSwitchChange}
        title="Execute conditionally"
        data-testid="ConditionalActionSection/Switcher"
      >
        <Hint>Execute your action according to dynamic statement.</Hint>
        <Stack gap={1}>
          {conditions[0].conditions_attributes.map((condition, i) => (
            <Condition
              key={condition.id || condition.nanoid}
              conditionIndex={i}
              ruleIndex={0}
              label={i === 0 ? 'If' : 'And'}
              experienceId={experienceId}
              mentionSteps={mentionSteps}
              conditionAttributes={condition}
              onChange={setConditions}
              withHiddenInput
              errors={errors.conditions && errors.conditions[i]}
            />
          ))}
          <NewItemButton size="small" onClick={handleAddCondition}>
            + Add condition
          </NewItemButton>
        </Stack>
      </SwitchWithTitle>
      <HiddenInputs
        conditions={conditions[0].conditions_attributes}
        isChecked={isChecked}
      />
    </Box>
  );
}
