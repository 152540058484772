import React from 'react';
import { ThumbsUp, ThumbsDown, HelpCircle } from 'react-feather';
import ResponseButton from '../../../shared/ResponseButton';
import { ButtonsContainer } from '../../style';
import BlockLabel from '../../../shared/BlockLabel';

const buttons = [
  {
    text: 'Yes',
    Icon: ThumbsUp,
    backgroundColor: '#43ca5a',
    fontColor: '#fff',
  },
  {
    text: 'No',
    Icon: ThumbsDown,
    backgroundColor: '#ff432e',
    fontColor: '#fff',
  },
  {
    text: 'Unsure',
    Icon: HelpCircle,
    backgroundColor: '#fff',
    fontColor: '#35353f',
  },
];

function QuickAnswerButtons({ askLabel }) {
  return (
    <div data-testid="Preview/QuickAnswerOptionsWrapper">
      <BlockLabel askLabel={askLabel} isPreview />
      <ButtonsContainer>
        {buttons.map(({ text, Icon, backgroundColor, fontColor }) => (
          <ResponseButton
            key={text}
            text={text}
            Icon={() => <Icon />}
            backgroundColor={backgroundColor}
            fontColor={fontColor}
          />
        ))}
      </ButtonsContainer>
    </div>
  );
}

export default QuickAnswerButtons;
