import React from 'react';
import PropTypes from 'prop-types';
import { Trash } from 'react-feather';

import { useModal } from '../../hooks';
import ArchiveFormModal from '../../modals/ArchiveFormModal';
import { DropdownItemWrapper, DropdownItemIcon } from './style';
import { useTranslation } from '../../../i18n';

function ArchiveFormDropdownItem({ archiveLink, redirectPath }) {
  const { isOpen, hideModal, showModal } = useModal();
  const { t } = useTranslation('translation', { keyPrefix: 'ArchiveFormDropdownItem'});

  return (
    <>
      <DropdownItemWrapper
        onClick={showModal}
        data-testid="ExperienceDropdown/Archive"
      >
        <DropdownItemIcon>
          <Trash />
        </DropdownItemIcon>
        {t('text')}
      </DropdownItemWrapper>
      <ArchiveFormModal
        isOpen={isOpen}
        hideModal={hideModal}
        archiveLink={archiveLink}
        redirectPath={redirectPath}
      />
    </>
  );
}

ArchiveFormDropdownItem.propTypes = {
  archiveLink: PropTypes.string.isRequired,
  redirectPath: PropTypes.string.isRequired,
};

export default ArchiveFormDropdownItem;
