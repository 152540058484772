import React from 'react';
import PropTypes from 'prop-types';
import { Text, List, ListItem, StyledSpan, StyledCheckIcon } from './style';
import { redirectTo } from '../../../utils';
import useWorkspace from '../../hooks/useWorkspace';

import Modal from '../../modal';

const listItems = [
  {
    text: 'More submissions',
    withStyledSpan: true,
  },
  {
    text: 'More texts',
    withStyledSpan: true,
  },
  {
    text: 'Lower processing fees',
  },
  {
    text: 'Remove our branding',
  },
];

function ModalContent() {
  return (
    <>
      <Text>
        The free version of Experience doesn't include this feature. Upgrade now
        to unlock these amazing benefits!
      </Text>
      <List>
        {listItems.map(({ text, withStyledSpan }) => (
          <ListItem key={text}>
            <StyledCheckIcon size={18} strokeWidth={3} />
            {text}
            {withStyledSpan && <StyledSpan>/ month </StyledSpan>}
          </ListItem>
        ))}
      </List>
    </>
  );
}

function UpgradePlanModal({ isOpen, hideModal }) {
  const workspaceId = useWorkspace();
  const billingPlansUrl = `/${workspaceId}/settings/billing`;
  const modalButtons = [
    {
      title: 'Later',
      onClick: hideModal,
    },
    {
      title: 'Upgrade Now',
      onClick: () => redirectTo(billingPlansUrl),
      primary: true,
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      title="Upgrade to access"
      content={<ModalContent />}
      buttons={modalButtons}
      hideModal={hideModal}
    />
  );
}

UpgradePlanModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default UpgradePlanModal;
