import styled from 'styled-components';

export const Title = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const EditIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  color: var(--font-color-secondary);
  transition: color 0.2s;
  cursor: pointer;

  &:hover {
    color: var(--font-color-primary);
  }
`;
