import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { MetricItemWrapper } from './style';
import Checkbox from '../../../../checkbox/index';
import { Legend, Fieldset } from '../../../../globals';

function NotificationSection({
  withResponderNotification,
  withAskerNotification,
}) {
  const [responderNotification, setResponderNotification] = useState(
    withResponderNotification,
  );
  const [askerNotification, setAskerNotification] = useState(
    withAskerNotification,
  );

  return (
    <Fieldset>
      <Legend>Email notifications</Legend>
      <MetricItemWrapper isChecked={responderNotification}>
        <Checkbox
          label="Send confirmation to Responder"
          checkedByDefault={responderNotification}
          onChange={(e) => setResponderNotification(e.target.checked)}
          withInput
          value={responderNotification}
          data-testid="NotificationSection/ResponderCheckbox"
          name="experience_settings_form_object[with_responder_notification]"
        />
      </MetricItemWrapper>
      <MetricItemWrapper isChecked={askerNotification}>
        <Checkbox
          label="Notify me about new responses"
          checkedByDefault={askerNotification}
          onChange={(e) => setAskerNotification(e.target.checked)}
          withInput
          value={askerNotification}
          data-testid="NotificationSection/AskerCheckbox"
          name="experience_settings_form_object[with_asker_notification]"
        />
      </MetricItemWrapper>
    </Fieldset>
  );
}

NotificationSection.propTypes = {
  withResponderNotification: PropTypes.bool.isRequired,
  withAskerNotification: PropTypes.bool.isRequired,
};

export default NotificationSection;
