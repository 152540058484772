import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 23.4375rem;

  .button--white {
    width: 6rem;
    height: 6rem;
    margin: 0 0.5rem;
    display: inline-flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 13px;
    max-width: 6.8rem;
    min-width: 5rem;
    text-transform: uppercase;
  }
`;
